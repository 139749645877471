import React, { useEffect, useState } from "react";
import BlogCard from "./BlogCard";
import axios from "axios";
import "./main-blogs.css";
import Navbar1 from "../../../components/Navbar";
import Footer from "../../../landing_page/Footer";
import teamWork from "../../../assets/landing-page/get-started.png";
import "../../../landing_page/offers.css";
import offerIcon from "../../../assets/landing-page/briefcase.png";
import { baseUrl } from "../../../utils/baseUrl";

const Blogs = () => {
  const [blogPosts, setBlogPosts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [filter, setFilter] = useState("");
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    // Fetch blog posts
    axios
      .get(`${baseUrl}/admin_panel/blog_PostList`)
      .then((response) => {
        setBlogPosts(response.data.data);
        setFilteredData(response.data.data);
      })
      .catch((error) => {
        console.error("Error fetching blog posts:", error);
      });

    axios
      .get("https://app.batuk.in/admin_panel/category_List")
      .then((response) => {
        setCategories(response.data.data);
      })
      .catch((error) => {
        console.error("Error fetching categories:", error);
      });
  }, []);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    filterAndSearch(e.target.value, filter);
  };

  const handleFilterChange = (e) => {
    setFilter(e.target.value);
    filterAndSearch(searchTerm, e.target.value);
  };

  const filterAndSearch = (term, filter) => {
    let filteredResults = blogPosts;

    if (filter) {
      filteredResults = filteredResults.filter(
        (item) => item.categoryId === filter
      );
    }

    if (term) {
      filteredResults = filteredResults.filter((item) =>
        item.title.toLowerCase().includes(term.toLowerCase())
      );
    }

    setFilteredData(filteredResults);
  };

  const handleShare = () => {
    alert("Share this blog post!");
  };

  return (
    <>
      <Navbar1 />
      <div className="blog-container">
        <div className="blog-hero-section">
          <div className="blog-hero-text-container">
            <h3 className="blog-hero-title">Boost your earnings with Batuk!</h3>
            <p className="blog-hero-description">
              Experience unparalleled support, smart tools, and step-by-step
              assistance. Join Batuk and transform your wealth journey today!
            </p>
            <h4 style={{ marginBottom: "1rem" }}>
              Subscribe to Our Newsletter
            </h4>
            <div
              style={{
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
              }}
            >
              <div className="newsletter">
                <input
                  type="email"
                  placeholder="Enter email"
                  className="subscribe-input"
                />
                <button className="subscribe-btn">Subscribe</button>
              </div>
            </div>
          </div>
          <div className="blog-hero-image-container">
            <img className="hero-image" src={teamWork} alt="Teamwork" />
          </div>
        </div>
      </div>
      <div className="offerings-container">
        <div
          className=""
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "30px",
            padding: " 9px",
          }}
        >
          <div className="row" style={{ width: "100%" }}>
            <div
              className="col-6"
              style={{ display: "flex", width: "58%", justifyContent: "end" }}
            >
              <img
                src={offerIcon}
                alt="Offerings Icon"
                className="offerings-icon"
              />
              <h2 className="offerings-title">Our Blogs</h2>
            </div>
            <div
              className="col-6"
              style={{ display: "flex", width: "42%", justifyContent: "end" }}
            >
              <div
                style={{
                  display: "flex",
                  border: "1px solid",
                  borderRadius: "5px",
                }}
              >
                <input
                  type="text"
                  placeholder="Search Blog"
                  value={searchTerm}
                  onChange={handleSearchChange}
                  style={{
                    flex: "1",
                    width: "13rem",
                  }}
                />
                <select
                  value={filter}
                  onChange={handleFilterChange}
                  style={{
                    padding: "12px",
                    borderRadius: "5px",
                    border: "1px solid #ccc",
                    width: "7rem",
                    paddingLeft: "34px",
                    // backgroundColor: "#4d16a4",
                  }}
                >
                  <option value="" hidden style={{ color: "white" }}>
                    Filter
                  </option>
                  {categories.map((cat, index) => (
                    <option key={index} value={cat._id}>
                      {cat.categoryName}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "3rem",
            marginBottom: "3rem",
          }}
        >
          <div className="blog-grid">
            {filteredData.map((post, index) => (
              <BlogCard
                key={index}
                blogId={post._id}
                author={"Batuk"}
                title={post.title}
                description={post.description}
                image={post.image}
                likes={post.likes}
                createdAt={post.createdAt}
                onShare={handleShare}
              />
            ))}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Blogs;

// import React from "react";
// import "./Blog.css"; // Import your CSS file
// import Navbar1 from "../../../components/Navbar";

// import Footer from "../../../landing_page/Footer";

// const Blogs = () => {
//   return (
//     <>
//       <Navbar1 />
//       {/* HEADER */}
//       <header
//         style={{
//           background:
//             "linear-gradient(rgba(1,1,1,.5), rgba(1,1,1,.5)), url(../images/#)",
//         }}
//       >
//         <section>
//           <h1 className="title">Our Blog</h1>
//         </section>
//       </header>

//       {/* BLOG SECTION */}
//       <div className="blog_container">
//         <div className="blog_content">
//           <div className="left_content">
//             {[...Array(7)].map((_, index) => (
//               <div className="blog_card" key={index}>
//                 <a href="article.html" className="figure">
//                   <img
//                     src="https://i.postimg.cc/KcwkZQsx/dummy.png"
//                     alt=""
//                     loading="lazy"
//                   />
//                   <span className="tag">15 JAN</span>
//                 </a>
//                 <section>
//                   <a href="#" className="title">
//                     Blog title goes here...
//                   </a>
//                   <p>
//                     Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
//                     do eiusmod tempor incididunt ut labore et dolore magna
//                     aliqua. Ut enim ad minim veniam, quis nostrud exercitation
//                     ullamco laboris nisi ut aliquip ex ea commodo consequat...
//                   </p>
//                 </section>
//               </div>
//             ))}
//           </div>
//           <button className="btn1 load-btn">Load more</button>
//         </div>

//         <div className="blog_content right_content">
//           {/* SEARCH COLUMN */}
//           <div className="columns search_column">
//             <section className="search">
//               <fieldset>
//                 <input
//                   type="text"
//                   name="search"
//                   placeholder="Search..."
//                   maxLength="100"
//                   required
//                 />
//               </fieldset>
//               <fieldset>
//                 <button type="submit" className="btn1">
//                   <em className="fa fa-search"></em>
//                 </button>
//               </fieldset>
//             </section>
//           </div>

//           {/* BOOKS COLUMN */}
//           <div className="columns books">
//             <span className="title">
//               New Books{" "}
//               <a href="#" title="Explore More">
//                 <em className="fa fa-share"></em>
//               </a>
//             </span>
//             <section>
//               <div className="cards">
//                 <div
//                   className="card_part card_part-one"
//                   style={{
//                     backgroundImage:
//                       "url(https://i.postimg.cc/2SD5y5RS/book-1.png)",
//                   }}
//                 ></div>
//                 <div
//                   className="card_part card_part-two"
//                   style={{
//                     backgroundImage:
//                       "url(https://i.postimg.cc/2SD5y5RS/book-1.png)",
//                   }}
//                 ></div>
//                 <div
//                   className="card_part card_part-three"
//                   style={{
//                     backgroundImage:
//                       "url(https://i.postimg.cc/2SD5y5RS/book-1.png)",
//                   }}
//                 ></div>
//                 <div
//                   className="card_part card_part-four"
//                   style={{
//                     backgroundImage:
//                       "url(https://i.postimg.cc/2SD5y5RS/book-1.png)",
//                   }}
//                 ></div>
//               </div>
//             </section>
//           </div>

//           {/* CATEGORIES COLUMN */}
//           <div className="columns categories">
//             <span className="title">Categories</span>
//             <section>
//               <a href="#">Bhagavad Gita</a>
//               <a href="#">Education</a>
//               <a href="#">Importance of reading</a>
//               <a href="#">Origin of vedas</a>
//               <a href="#">Srimad bhagavatam</a>
//               <a href="#">Krishna</a>
//               <a href="#">Process of acquiring knowledge</a>
//             </section>
//           </div>

//           {/* POSTS COLUMN */}
//           <div className="columns posts">
//             <span className="title">
//               Recent Posts{" "}
//               <a href="#" title="Explore More">
//                 <em className="fa fa-share"></em>
//               </a>
//             </span>
//             <section>
//               {[...Array(3)].map((_, index) => (
//                 <a href="#" key={index}>
//                   <img
//                     src="https://i.postimg.cc/KcwkZQsx/dummy.png"
//                     alt=""
//                     loading="lazy"
//                   />
//                   <p>Be brave, be strong, be bold. Man dies but once.</p>
//                 </a>
//               ))}
//             </section>
//           </div>

//           {/* COMMENTS COLUMN */}
//           <div className="columns comments">
//             <span className="title">
//               Recent Comments{" "}
//               <a href="#" title="Explore More">
//                 <em className="fa fa-share"></em>
//               </a>
//             </span>
//             {/* <section>
//               <marquee
//                 direction="up"
//                 scrollAmount="4"
//                 onMouseOver={() => this.stop()}
//                 onMouseOut={() => this.start()}
//                 className="marquee2"
//               >
//                 <p>
//                   Remember, torn clothes should not be left at home. Dispose of
//                   them out. Buying new clothes like towels.
//                 </p>
//                 <p>
//                   Wearing clothes, bedsheets are like inviting good luck to the
//                   home.
//                 </p>
//                 <p>
//                   Arrange doormats before every door and please change the
//                   doormats once in 6/8 months or maximum within 1 year. For More
//                   Daily
//                 </p>
//               </marquee>
//             </section> */}
//           </div>

//           {/* SOCIAL MEDIA ICONS */}
//           <div className="columns social_icons">
//             <a href="#" title="Facebook">
//               <em className="fa fa-facebook"></em>
//             </a>
//             <a href="#" title="Instagram">
//               <em className="fa fa-instagram"></em>
//             </a>
//             <a href="#" title="Youtube">
//               <em className="fa fa-youtube"></em>
//             </a>
//             <a href="#" title="Whatsapp">
//               <em className="fa fa-whatsapp"></em>
//             </a>
//             <a href="#" title="Telegram">
//               <em className="fa fa-telegram"></em>
//             </a>
//           </div>
//         </div>
//       </div>
//       <Footer />
//     </>
//   );
// };

// export default Blogs;

// import React, { useEffect, useState } from "react";
// import BlogCard from "./BlogCard";
// import axios from "axios";
// import "./main-blogs.css";
// import Navbar1 from "../../../components/Navbar";
// import Footer from "../../../landing_page/Footer";
// import teamWork from "../../../assets/landing-page/get-started.png";

// import "../../../landing_page/offers.css";
// import offerIcon from "../../../assets/landing-page/briefcase.png";
// import { baseUrl } from "../../../utils/baseUrl";

// // Sample data for demonstration
// const sampleData = [
//   { id: 1, title: "React Developer", category: "Frontend" },
//   { id: 2, title: "Node.js Developer", category: "Backend" },
//   { id: 4, title: "UI/UX Designer", category: "Design" },
//   { id: 3, title: "Full Stack Developer", category: "Full Stack" },
//   { id: 5, title: "UI/UX Designer", category: "Design" },
//   { id: 6, title: "Angular Developer", category: "Frontend" },
//   { id: 7, title: "Node.js Developer", category: "Backend" },
//   { id: 8, title: "Full Stack Developer", category: "Full Stack" },
//   { id: 9, title: "UI/UX Designer", category: "Design" },
//   { id: 10, title: "Vue Developer", category: "Frontend" },
//   { id: 11, title: "Node.js Developer", category: "Backend" },
//   { id: 12, title: "Full Stack Developer", category: "Full Stack" },
// ];

// const Blogs = () => {
//   const [blogPosts, setBlogPosts] = useState([]);
//   const [categories, setCategories] = useState([]);

//   useEffect(() => {
//     axios
//       .get(`${baseUrl}/admin_panel/blog_PostList`)
//       .then((response) => {
//         setBlogPosts(response.data.data);
//       })
//       .catch((error) => {
//         console.error("Error fetching blog posts:", error);
//       });
//   }, []);

//   const handleShare = () => {
//     alert("Share this blog post!");
//   };

//   // Fetch categories from the API when the component mounts
//   useEffect(() => {
//     fetchCategories();
//   }, []);

//   // Fetch categories (READ operation)
//   const fetchCategories = async () => {
//     try {
//       const response = await axios.get(
//         "https://app.batuk.in/admin_panel/category_List"
//       ); // Replace with your API endpoint
//       setCategories(response.data.data);
//       console.log(response.data.data);
//     } catch (error) {
//       console.error("Error fetching categories:", error);
//     }
//   };

//   const [searchTerm, setSearchTerm] = useState("");
//   const [filter, setFilter] = useState("");
//   const [filteredData, setFilteredData] = useState(sampleData);

//   // Handle search input change
//   const handleSearchChange = (e) => {
//     setSearchTerm(e.target.value);
//     search(e.target.value, filter);
//   };

//   // Handle filter change
//   const handleFilterChange = (e) => {
//     setFilter(e.target.value);
//     search(searchTerm, e.target.value);
//   };

//   // Function to filter and search data
//   const search = (term, filter) => {
//     let filteredResults = sampleData;

//     if (filter) {
//       filteredResults = filteredResults.filter((item) =>
//         item.category.toLowerCase().includes(filter.toLowerCase())
//       );
//     }

//     if (term) {
//       filteredResults = filteredResults.filter((item) =>
//         item.title.toLowerCase().includes(term.toLowerCase())
//       );
//     }

//     setFilteredData(filteredResults);
//   };

//   return (
//     <>
//       <Navbar1 />
//       <div className="blog-container">
//         <div className="blog-hero-section">
//           <div className="blog-hero-text-container">
//             <h3 className="blog-hero-title">Boost your earnings with Batuk!</h3>
//             <p className="blog-hero-description">
//               Experience unparalleled support, smart tools, and step-by-step
//               assistance. Join Batuk and transform your wealth journey today!
//             </p>
//             <h4 style={{ marginBottom: "1rem" }}>
//               Subscribe to Our Newsletter
//             </h4>
//             <div
//               style={{
//                 display: "flex",
//                 justifyContent: "start",
//                 alignItems: "center",
//               }}
//             >
//               <div className="newsletter">
//                 <input
//                   type="email"
//                   placeholder="Enter email"
//                   className="subscribe-input"
//                 />
//                 <button className="subscribe-btn">Subscribe</button>
//               </div>
//             </div>
//           </div>
//           <div className="blog-hero-image-container">
//             <img className="hero-image" src={teamWork} alt="Teamwork" />
//           </div>
//         </div>
//       </div>
//       <div className="offerings-container">
//         <div
//           className=""
//           style={{
//             display: "flex",
//             justifyContent: "center",
//             alignTtems: "center",
//             marginTop: "30px",
//             padding: " 9px",
//           }}
//         >
//           <div class="row" style={{ width: "100%" }}>
//             <div
//               class="col-6"
//               style={{ display: "flex", width: "58%", justifyContent: "end" }}
//             >
//               <img
//                 src={offerIcon}
//                 alt="Offerings Icon"
//                 className="offerings-icon"
//               />
//               <h2 className="offerings-title">Our Blogs</h2>
//             </div>
//             <div
//               class="col-6"
//               style={{ display: "flex", width: "42%", justifyContent: "end" }}
//             >
//               <div
//                 style={{
//                   display: "flex",
//                   border: "1px solid",
//                   borderRadius: "5px",
//                 }}
//               >
//                 <input
//                   type="text"
//                   placeholder="Search Blog"
//                   value={searchTerm}
//                   onChange={handleSearchChange}
//                   style={{
//                     flex: "1",
//                     width: "13rem",
//                   }}
//                 />
//                 <select
//                   value={filter}
//                   onChange={handleFilterChange}
//                   style={{
//                     padding: "12px",
//                     borderRadius: "5px",
//                     border: "1px solid #ccc",
//                     width: "7rem",
//                     paddingLeft: "34px",
//                     backgroundColor: "#4d16a4",
//                   }}
//                 >
//                   <option value="" hidden style={{ color: "white" }}>
//                     Filter
//                   </option>
//                   {categories.map((cat, index) => (
//                     <option key={index} value={cat._id}>
//                       {cat.categoryName}
//                     </option>
//                   ))}
//                 </select>
//               </div>
//             </div>
//           </div>
//         </div>
//         <div
//           style={{
//             display: "flex",
//             justifyContent: "center",
//             alignItems: "center",
//             marginTop: "3rem",
//             marginBottom: "3rem",
//           }}
//         >
//           <div className="blog-grid">
//             {/* Render Filtered Data */}
//             {/* <ul>
//               {filteredData.map((item) => (
//                 <li key={item.id}>{item.title}</li>
//               ))}
//             </ul> */}
//             {blogPosts.map((post, index) => (
//               <BlogCard
//                 key={index}
//                 blogId={post._id}
//                 author={"Batuk"}
//                 title={post.title}
//                 description={post.description}
//                 image={post.image}
//                 likes={post.likes}
//                 createdAt={post.createdAt}
//                 onShare={handleShare}
//               />
//             ))}
//           </div>
//         </div>
//       </div>
//       <Footer />
//     </>
//   );
// };

// export default Blogs;
