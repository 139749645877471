import React, { useState } from "react";
import { Button, TextField } from "@mui/material";
import "../../assets/css/contact.css";
import { FiPhone } from "react-icons/fi";
import { MdOutlineEmail } from "react-icons/md";
import { IoLocationOutline } from "react-icons/io5";

import Navbar1 from "../../components/Navbar";
import Footer from "../../landing_page/Footer";
import axios from "axios";
import { baseUrl } from "../../utils/baseUrl";

const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    mobileNo: "",
    subject: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const data = {
      name: formData.name,
      mailto: formData.email,
      mobileNo: formData.mobileNo,
      subject: formData.subject,
      message: formData.message,
    };

    try {
      const response = await axios.post(
        `${baseUrl}/admin_panel/contact`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      console.log(response.status, "contact response");

      if (response.status === 200) {
        // Handle success (e.g., show a success message)
        alert(response.data.message);
        setFormData({
          name: "",
          email: "",
          mobileNo: "",
          subject: "",
          message: "",
        });
      }
    } catch (error) {
      // Handle error (e.g., show an error message)
      alert("There was a problem sending your message.");
    }
  };

  return (
    <>
      <Navbar1 />
      <div className="contact-banner1">
        <div
          style={{
            width: "60%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <h1>We're here for you!</h1>
          <p style={{ fontSize: "22px", fontWeight: "500" }}>
            If you need assistance or have any questions, please reach out to us
            using the form below. Your feedback matters to us.
          </p>
        </div>
      </div>
      <div className="contact-main-box">
        <div className="contact-inner-box1">
          <div className="contact-card">
            <IoLocationOutline size={70} />
            <div>
              <h4>Address</h4>
              <p>
                Office No 2, 3rd floor, A-1, Sector 9, Noida, Uttar Pradesh-
                201301
              </p>
            </div>
          </div>
          <div className="contact-card">
            <FiPhone size={40} />
            <div>
              <h4>Call Us</h4>
              <p>+91 8448695359</p>
            </div>
          </div>
          <div className="contact-card">
            <MdOutlineEmail size={40} />
            <div>
              <h4>Email Us</h4>
              <p>connect@batuk.in</p>
            </div>
          </div>
        </div>
        <div className="contact-inner-box2">
          <h1>Contact Details</h1>
          <form className="contact-form-box" onSubmit={handleSubmit}>
            <TextField
              name="name"
              label="Full Name"
              variant="standard"
              required
              value={formData.name}
              onChange={handleChange}
            />
            <TextField
              name="email"
              label="Email"
              variant="standard"
              value={formData.email}
              onChange={handleChange}
            />
            <TextField
              name="mobileNo"
              label="Mobile Number"
              variant="standard"
              value={formData.mobileNo}
              onChange={handleChange}
            />
            <TextField
              name="subject"
              label="Subject"
              variant="standard"
              value={formData.subject}
              onChange={handleChange}
            />
            <TextField
              name="message"
              label="Your Message"
              variant="standard"
              multiline
              rows={4}
              value={formData.message}
              onChange={handleChange}
            />
            <div>
              <Button type="submit" variant="contained">
                Submit
              </Button>
            </div>
          </form>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Contact;

// import React from "react";
// import { Button, TextField } from "@mui/material";
// import "../../assets/css/contact.css";
// import { FiPhone } from "react-icons/fi";
// import { MdOutlineEmail } from "react-icons/md";
// import { IoLocationOutline } from "react-icons/io5";

// import Navbar1 from "../../components/Navbar";
// import Footer from "../../landing_page/Footer";

// const Contact = () => {
//   return (
//     <>
//       <Navbar1 />
//       <div className="contact-banner1">
//         <div style={{ width: "60%" }}>
//           <h1>We're here for you!</h1>
//           <p style={{ fontSize: "22px", fontWeight: "500" }}>
//             If you need assistance or have any questions, please reach out to us
//             using the form below. Your feedback matters to us.
//           </p>
//         </div>
//       </div>
//       <div className="contact-main-box">
//         <div className="contact-inner-box1">
//           <div className="contact-card">
//             <IoLocationOutline size={70} />
//             <div>
//               <h4>Address</h4>
//               <p>
//                 Office No 2, 3rd floor, A-1, Sector 9, Noida, Uttar Pradesh-
//                 201301
//               </p>
//             </div>
//           </div>
//           <div className="contact-card">
//             <FiPhone size={40} />
//             <div>
//               <h4>Call Us</h4>
//               <p>+91 8448695359</p>
//             </div>
//           </div>
//           <div className="contact-card">
//             <MdOutlineEmail size={40} />
//             <div>
//               <h4>Email Us</h4>
//               <p>connect@batuk.in</p>
//             </div>
//           </div>
//         </div>
//         <div className="contact-inner-box2">
//           <h1>Contact Details</h1>
//           <form className="contact-form-box">
//             <TextField
//               id="standard-basic"
//               label="Full Name"
//               variant="standard"
//               required
//             />
//             <TextField id="standard-basic" label="Email" variant="standard" />
//             <TextField
//               id="standard-basic"
//               label="Mobile Number"
//               variant="standard"
//             />
//             <TextField id="standard-basic" label="Subject" variant="standard" />
//             <TextField
//               id="standard-basic"
//               label="Your Message"
//               variant="standard"
//             />
//             <div>
//               <Button type="submit" variant="contained">
//                 Submit
//               </Button>
//             </div>
//           </form>
//         </div>
//       </div>
//       <Footer />
//     </>
//   );
// };

// export default Contact;

// import React from "react";
// import "../../assets/css/contact.css";
// import NavBar1 from "../../components/Navbar";
// import Footer from "../../landing_page/Footer";
// import { FaPhoneVolume } from "react-icons/fa6";
// import { MdEmail } from "react-icons/md";
// import { FaRegAddressCard } from "react-icons/fa6";

// const Contact = () => {
//   return (
//     <>
//       <NavBar1 />
//       <section className="contact" id="contact">
//         <div className="contact-heading">
//           <h2>
//             GET IN <span> TOUCH </span>
//           </h2>
//           <p>Contact Me</p>
//         </div>
//         <div
//           className="container"
//           style={{ maxHeight: "550px", borderRadius: "8px", zIndex: "2" }}
//         >
//           <div className="row">
//             <div className="col-md-5">
//               <div className="contact-title">
//                 <h4 style={{ color: "#4d16a4" }}>Contact Details</h4>
//               </div>
//               <div className="contact-info">
//                 <div className="info">
//                   <div
//                     style={{
//                       display: "flex",
//                       justifyContent: "center",
//                       alignItems: "center",
//                     }}
//                   >
//                     <FaPhoneVolume className="icon-size" />
//                   </div>
//                   <h4
//                     style={{
//                       display: "flex",
//                       justifyContent: "center",
//                       alignItems: "center",
//                       fontSize: "15px",
//                       color: "#4d16a4",
//                     }}
//                   >
//                     +91844695359
//                   </h4>
//                 </div>
//                 <div className="info">
//                   <div
//                     style={{
//                       display: "flex",
//                       justifyContent: "center",
//                       alignItems: "center",
//                     }}
//                   >
//                     <MdEmail className="icon-size" />
//                   </div>
//                   <h4
//                     style={{
//                       display: "flex",
//                       justifyContent: "center",
//                       alignItems: "center",
//                       fontSize: "15px",
//                       color: "#4d16a4",
//                     }}
//                   >
//                     connect@batuk.in
//                   </h4>
//                 </div>
//                 <div className="info" style={{ marginBottom: "1rem" }}>
//                   <div
//                     style={{
//                       display: "flex",
//                       justifyContent: "center",
//                       alignItems: "center",
//                     }}
//                   >
//                     <FaRegAddressCard className="icon-size" />
//                   </div>
//                   <h4
//                     style={{
//                       display: "flex",
//                       justifyContent: "center",
//                       alignItems: "center",
//                       fontSize: "15px",
//                       color: "#4d16a4",
//                     }}
//                   >
//                     Office No 2, 3rd floor, A-1, Sector 9, Noida, Uttar Pradesh-
//                     201301
//                   </h4>
//                 </div>
//               </div>
//             </div>
//             <div className="col-md-7">
//               <div className="contact-title">
//                 <h4 style={{ color: "#4d16a4" }}>Ask your query</h4>
//               </div>
//               <form className="contact-form">
//                 <div className="row">
//                   <div className="col-sm-6">
//                     <input
//                       type="text"
//                       className="form-control"
//                       placeholder="Name"
//                     />
//                   </div>
//                   <div className="col-sm-6">
//                     <input
//                       type="email"
//                       className="form-control"
//                       placeholder="Email"
//                     />
//                   </div>
//                   <div className="col-sm-12">
//                     <input
//                       type="text"
//                       className="form-control"
//                       placeholder="Subject"
//                     />
//                   </div>
//                 </div>
//                 <div className="form-group">
//                   <textarea
//                     className="form-control"
//                     rows="5"
//                     id="comment"
//                     placeholder="Message"
//                   ></textarea>
//                 </div>
//                 <button
//                   className="btn btn-block"
//                   type="submit"
//                   style={{ marginBottom: "1rem" }}
//                 >
//                   Send Now!
//                 </button>
//               </form>
//             </div>
//           </div>
//         </div>
//       </section>
//       <Footer />
//     </>
//   );
// };

// export default Contact;
