import React, { useEffect, useState } from "react";
import { BarChart } from "@mui/x-charts/BarChart";
import { axisClasses } from "@mui/x-charts/ChartsAxis";
import axios from "axios";
import ApexCharts from "apexcharts";
import { Card, Row, Col, Container } from "react-bootstrap";
import {
  MaterialReactTable,
  createMRTColumnHelper,
  useMaterialReactTable,
} from "material-react-table";
import { Box, Skeleton } from "@mui/material";
import Button from "@mui/material/Button";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { mkConfig, generateCsv, download } from "export-to-csv";

import TotalAmount from "../assets/Icons/Untitled design (2).png";
import TotalGoldAmount from "../assets/Icons/Untitled design (3).png";
import TotalSilverAmount from "../assets/Icons/Untitled design (5).png";
import TotalGoldGrams from "../assets/Icons/Untitled design (4).png";
import TotalSilverGrams from "../assets/Icons/Untitled design (6).png";
import { baseUrl } from "../utils/baseUrl";
import TableSkeleton from "../utils/TableSkeleton";
import MainCardSkeleton from "../utils/MainCardSkeleton";

const columnHelper = createMRTColumnHelper();

const columns = [
  columnHelper.accessor("clientDetails.name", {
    header: "Name",
    size: 40,
  }),
  columnHelper.accessor("clientDetails.mobileNo", {
    header: "Mobile Number",
    size: 40,
  }),
  columnHelper.accessor("clientDetails.Email", {
    header: "Email Id",
    size: 40,
  }),
  columnHelper.accessor("totalInvestment", {
    header: "Total Investment",
    size: 40,
    Cell: (cell) => "₹" + cell.row.original.totalInvestment,
  }),
];

const csvConfig = mkConfig({
  fieldSeparator: ",",
  decimalSeparator: ".",
  useKeysAsHeaders: true,
});

function Dashboard() {
  const Token = sessionStorage.getItem("token");
  const [loading, setLoading] = useState(true);
  const [TotalAugmontData, setTotalAugmontData] = useState([]);
  const [graphdata, setGraphData] = useState([]);
  const [topInvesters, setTopInvesters] = useState([]);
  const [dataReady, setDataReady] = useState(false);

  const totalSilverPrecentage = TotalAugmontData.totalSilverPrecentage || "0%";
  const totalGoldPercentage = TotalAugmontData.totalGoldPercentage || "0%";

  const percentageSilver = parseFloat(totalSilverPrecentage.replace("%", ""));
  const percentageGold = parseFloat(totalGoldPercentage.replace("%", ""));

  useEffect(() => {
    const fetchData = async () => {
      await getTotalAugmontData();
      await getGraphData();
      await getTopInvesters();
      setDataReady(true);
    };

    fetchData();
  }, []);

  const getTotalAugmontData = async () => {
    try {
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${Token}`,
      };

      const response = await axios.get(`${baseUrl}/getTotalInvestments`, {
        headers,
      });
      console.log(response.data, "taotalllllllllllllll");
      setTotalAugmontData(response.data.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching total Augmont data:", error);
    }
  };

  const getGraphData = async () => {
    try {
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${Token}`,
      };

      const response = await axios.get(`${baseUrl}/graphdata`, {
        headers,
      });
      const processedData = response.data.data.map((item) => ({
        month: item.month,
        gold: item.totalGold,
        silver: item.totalSilver,
        year: item.year,
      }));
      setGraphData(processedData);
    } catch (error) {
      console.error("Error fetching graph data:", error);
    }
  };

  const getTopInvesters = async () => {
    try {
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${Token}`,
      };

      const response = await axios.get(`${baseUrl}/topInvesters`, {
        headers,
      });
      setLoading(false);
      const investors = response.data.data.topClients;
      const data = investors.map((investor, index) => ({
        ...investor,
        slno: index + 1,
      }));
      setTopInvesters(data);
    } catch (error) {
      console.error("Error fetching top investors:", error);
    }
  };

  const flattenData = (data) => {
    return data.map((item) => ({
      slno: item.slno,
      name: item.clientDetails.name,
      mobileNo: item.clientDetails.mobileNo,
      email: item.clientDetails.Email,
      totalInvestment: item.totalInvestment,
    }));
  };

  const handleExportData = () => {
    const flattenedData = flattenData(topInvesters);
    const csv = generateCsv(csvConfig)(flattenedData);
    download(csvConfig)(csv);
  };

  const table = useMaterialReactTable({
    columns,
    data: topInvesters,
    enableRowSelection: false,
    enableGlobalFilter: true,
    columnFilterDisplayMode: "popover",
    enableColumnActions: false,
    enableColumnFilters: false,
    enableSorting: true,
    enableDensityToggle: false,
    enableHiding: false,
    maxSize: 100,
    enableRowActions: false,
    positionActionsColumn: "last",
    muiTableHeadCellProps: {
      sx: (theme) => ({
        color: "#4a2a7d",
        background: "#DCDAFF",
        fontWeight: "normal",
        fontSize: "10px",
      }),
    },
    renderTopToolbarCustomActions: ({ table }) => (
      <Box
        sx={{
          display: "flex",
          gap: "16px",
          padding: "8px",
          flexWrap: "wrap",
        }}
      >
        <Button onClick={handleExportData} startIcon={<FileDownloadIcon />}>
          Export All Data
        </Button>
      </Box>
    ),
  });

  const chartSetting = {
    yAxis: [
      {
        label: "Grams",
      },
    ],
    width: 1000,
    height: 400,
    padding: "1rem",
    sx: {
      [`.${axisClasses.left} .${axisClasses.label}`]: {
        transform: "translate(-20px, -20px)",
      },
    },
  };

  const valueFormatter = (value) => `${value}gm`;

  useEffect(() => {
    if (dataReady) {
      const optionsVisitorsProfile = {
        series: [percentageSilver, percentageGold],
        labels: ["Silver", "Gold"],
        colors: ["#C0C0C0", "#FFD700"],
        chart: {
          type: "donut",
          width: "100%",
          height: "350px",
        },
        legend: {
          position: "bottom",
        },
        plotOptions: {
          pie: {
            donut: {
              size: "30%",
            },
          },
        },
      };

      const chartVisitorsProfile = new ApexCharts(
        document.getElementById("chart-visitors-profile"),
        optionsVisitorsProfile
      );

      chartVisitorsProfile.render();

      return () => {
        chartVisitorsProfile.destroy();
      };
    }
  }, [dataReady, percentageSilver, percentageGold]);

  return (
    <div className="dashboard section">
      <div class="page-content">
        <section class="row">
          <div className="col-12 col-lg-9">
            <div className="row">
              <div className="col-12 col-lg-4 col-md-6">
                <div className="card" style={{ width: "100%" }}>
                  <div className="card-body px-4 py-4-5">
                    <div className="row">
                      <div className="col-md-4 col-lg-12 col-xl-12 col-xxl-5 d-flex justify-content-start">
                        <div className="stats-icon purple mb-2">
                          <img
                            src={TotalAmount}
                            alt="Total Amount"
                            width="100"
                            className="me-3"
                          />
                        </div>
                      </div>
                      <div className="col-md-8 col-lg-12 col-xl-12 col-xxl-7">
                        <h6 className="text-muted font-semibold">
                          Total Amount
                        </h6>
                        {loading ? (
                          <Skeleton variant="text" width={100} height={20} />
                        ) : (
                          <h6 className="font-extrabold mb-0">
                            ₹ {TotalAugmontData.totalAumAmount}
                          </h6>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12 col-lg-4 col-md-6">
                <div className="card" style={{ width: "100%" }}>
                  <div className="card-body px-4 py-4-5">
                    <div className="row">
                      <div className="col-md-4 col-lg-12 col-xl-12 col-xxl-5 d-flex justify-content-start">
                        <div className="stats-icon blue mb-2">
                          <img
                            src={TotalGoldAmount}
                            alt="Total Gold Amount"
                            width="100"
                            className="me-3"
                          />
                        </div>
                      </div>
                      <div className="col-md-8 col-lg-12 col-xl-12 col-xxl-7">
                        <h6 className="text-muted font-semibold">
                          Gold Amount
                        </h6>
                        <h6 className="font-extrabold mb-0">
                          ₹ {TotalAugmontData.totalGoldAmount}
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12 col-lg-4 col-md-6">
                <div className="card" style={{ width: "100%" }}>
                  <div className="card-body px-4 py-4-5">
                    <div className="row">
                      <div className="col-md-4 col-lg-12 col-xl-12 col-xxl-5 d-flex justify-content-start">
                        <div className="stats-icon green mb-2">
                          <img
                            src={TotalSilverAmount}
                            alt="Total Silver Amount"
                            width="100"
                            className="me-3"
                          />
                        </div>
                      </div>
                      <div className="col-md-8 col-lg-12 col-xl-12 col-xxl-7">
                        <h6 className="text-muted font-semibold">
                          Silver Amount
                        </h6>
                        <h6 className="font-extrabold mb-0">
                          ₹ {TotalAugmontData.totalSilverAmount}
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12 col-lg-4 col-md-6">
                <div className="card" style={{ width: "100%" }}>
                  <div className="card-body px-4 py-4-5">
                    <div className="row">
                      <div className="col-md-4 col-lg-12 col-xl-12 col-xxl-5 d-flex justify-content-start">
                        <div className="stats-icon purple mb-2">
                          <img
                            src={TotalAmount}
                            alt="Total Amount"
                            width="100"
                            className="me-3"
                          />
                        </div>
                      </div>
                      <div className="col-md-8 col-lg-12 col-xl-12 col-xxl-7">
                        <h6 className="text-muted font-semibold">
                          Total Grams
                        </h6>
                        <h6 className="font-extrabold mb-0">
                          ₹ {TotalAugmontData.totalGrams}
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12 col-lg-4 col-md-6">
                <div className="card" style={{ width: "100%" }}>
                  <div className="card-body px-4 py-4-5">
                    <div className="row">
                      <div className="col-md-4 col-lg-12 col-xl-12 col-xxl-5 d-flex justify-content-start">
                        <div className="stats-icon red mb-2">
                          <img
                            src={TotalGoldGrams}
                            alt="Total Gold Grams"
                            width="100"
                            className="me-3"
                          />
                        </div>
                      </div>
                      <div className="col-md-8 col-lg-12 col-xl-12 col-xxl-7">
                        <h6 className="text-muted font-semibold">Gold Grams</h6>
                        <h6 className="font-extrabold mb-0">
                          {TotalAugmontData.totalGoldGrams}g
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12 col-lg-4 col-md-6">
                <div className="card" style={{ width: "100%" }}>
                  <div className="card-body px-4 py-4-5">
                    <div className="row">
                      <div className="col-md-4 col-lg-12 col-xl-12 col-xxl-5 d-flex justify-content-start">
                        <div className="stats-icon red mb-2">
                          <img
                            src={TotalSilverGrams}
                            alt="Total Silver Grams"
                            width="100"
                            className="me-3"
                          />
                        </div>
                      </div>
                      <div className="col-md-8 col-lg-12 col-xl-12 col-xxl-7">
                        <h6 className="text-muted font-semibold">
                          Silver Grams
                        </h6>
                        <h6 className="font-extrabold mb-0">
                          {TotalAugmontData.totalSilverGrams}g
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-3">
            <div class="card" style={{ width: "100%" }}>
              <div class="card-header">
                <h4>Total Gold/Silver</h4>
              </div>
              <div class="card-body">
                <div id="chart-visitors-profile"></div>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-12">
            <div className="row">
              <div className="col-12">
                <div className="card" style={{ width: "100%" }}>
                  <div className="card-header">
                    <h4>Graph Data</h4>
                  </div>
                  <div className="card-body">
                    <div id="chart-profile-visit">
                      <Col
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <BarChart
                          dataset={graphdata}
                          xAxis={[{ scaleType: "band", dataKey: "month" }]}
                          yAxis={{ dataKey: "year", scaleType: "linear" }}
                          series={[
                            {
                              dataKey: "gold",
                              label: "Gold",
                              color: "gold",
                              valueFormatter,
                            },
                            {
                              dataKey: "silver",
                              label: "Silver",
                              color: "silver",
                              valueFormatter,
                            },
                          ]}
                          {...chartSetting}
                        />
                      </Col>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Container fluid>
        <Row>
          <Col xs={12}>
            <h2 className="TABLE_HEADING">Top Clients</h2>
            {loading ? <TableSkeleton /> : <MaterialReactTable table={table} />}
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Dashboard;

// import React, { useEffect, useState } from "react";
// import { BarChart } from "@mui/x-charts/BarChart";
// import { axisClasses } from "@mui/x-charts/ChartsAxis";
// import axios from "axios";
// import ApexCharts from "apexcharts";
// import { Card, Row, Col, Container } from "react-bootstrap";
// import {
//   MaterialReactTable,
//   createMRTColumnHelper,
//   useMaterialReactTable,
// } from "material-react-table";
// import { Box } from "@mui/material";
// import Paper from "@mui/material/Paper";
// import Table from "@mui/material/Table";
// import TableBody from "@mui/material/TableBody";
// import TableCell from "@mui/material/TableCell";
// import TableContainer from "@mui/material/TableContainer";
// import TableHead from "@mui/material/TableHead";
// import TablePagination from "@mui/material/TablePagination";
// import TableRow from "@mui/material/TableRow";

// import CardActions from "@mui/material/CardActions";
// import CardContent from "@mui/material/CardContent";
// import CardMedia from "@mui/material/CardMedia";
// import Button from "@mui/material/Button";
// import Typography from "@mui/material/Typography";
// import FileDownloadIcon from "@mui/icons-material/FileDownload";
// import { mkConfig, generateCsv, download } from "export-to-csv";

// import TotalAmount from "../assets/Icons/Untitled design (2).png";
// import TotalGoldAmount from "../assets/Icons/Untitled design (3).png";
// import TotalSilverAmount from "../assets/Icons/Untitled design (5).png";
// import TotalGoldGrams from "../assets/Icons/Untitled design (4).png";
// import TotalSilverGrams from "../assets/Icons/Untitled design (6).png";
// import { baseUrl } from "../utils/baseUrl";

// const columnHelper = createMRTColumnHelper();

// const columns = [
//   columnHelper.accessor("clientDetails.name", {
//     header: "Name",
//     size: 40,
//   }),
//   columnHelper.accessor("clientDetails.mobileNo", {
//     header: "Mobile Number",
//     size: 40,
//   }),
//   columnHelper.accessor("clientDetails.Email", {
//     header: "Email Id",
//     size: 40,
//   }),
//   columnHelper.accessor("totalInvestment", {
//     header: "Total Investment",
//     size: 40,
//     Cell: (cell) => "₹" + cell.row.original.totalInvestment,
//   }),
// ];

// const csvConfig = mkConfig({
//   fieldSeparator: ",",
//   decimalSeparator: ".",
//   useKeysAsHeaders: true,
// });

// function Dashboard() {
//   const Token = sessionStorage.getItem("token");
//   const [TotalAugmontData, setTotalAugmontData] = useState({});
//   const [graphdata, setGraphData] = useState([]);
//   const [topInvesters, setTopInvesters] = useState([]);

//   useEffect(() => {
//     getTotalAugmontData();
//     getGraphData();
//     getTopInvesters();
//   }, []);

//   const getTotalAugmontData = async () => {
//     try {
//       const headers = {
//         "Content-Type": "application/json",
//         Accept: "application/json",
//         Authorization: `Bearer ${Token}`,
//       };

//       const response = await axios.get(`${baseUrl}/getTotalInvestments`, {
//         headers,
//       });
//       setTotalAugmontData(response.data.data);
//     } catch (error) {
//       console.error("Error fetching total Augmont data:", error);
//     }
//   };

//   const getGraphData = async () => {
//     try {
//       const headers = {
//         "Content-Type": "application/json",
//         Accept: "application/json",
//         Authorization: `Bearer ${Token}`,
//       };

//       const response = await axios.get(`${baseUrl}/graphdata`, {
//         headers,
//       });
//       const processedData = response.data.data.map((item) => ({
//         month: item.month,
//         gold: item.totalGold,
//         silver: item.totalSilver,
//         year: item.year,
//       }));
//       setGraphData(processedData);
//     } catch (error) {
//       console.error("Error fetching graph data:", error);
//     }
//   };

//   const getTopInvesters = async () => {
//     try {
//       const headers = {
//         "Content-Type": "application/json",
//         Accept: "application/json",
//         Authorization: `Bearer ${Token}`,
//       };

//       const response = await axios.get(`${baseUrl}/topInvesters`, {
//         headers,
//       });
//       const investors = response.data.data.topClients;
//       const data = investors.map((investor, index) => {
//         return { ...investor, slno: index + 1 };
//       });
//       setTopInvesters(data);
//     } catch (error) {
//       console.error("Error fetching top investors:", error);
//     }
//   };

//   const flattenData = (data) => {
//     return data.map((item) => ({
//       slno: item.slno,
//       name: item.clientDetails.name,
//       mobileNo: item.clientDetails.mobileNo,
//       email: item.clientDetails.Email,
//       totalInvestment: item.totalInvestment,
//     }));
//   };

//   const handleExportData = () => {
//     const flattenedData = flattenData(topInvesters);
//     const csv = generateCsv(csvConfig)(flattenedData);
//     download(csvConfig)(csv);
//   };

//   const table = useMaterialReactTable({
//     columns,
//     data: topInvesters,
//     enableRowSelection: false,
//     enableGlobalFilter: true,
//     columnFilterDisplayMode: "popover",
//     enableColumnActions: false,
//     enableColumnFilters: false,
//     enableSorting: true,
//     enableDensityToggle: false,
//     enableHiding: false,
//     maxSize: 100,
//     enableRowActions: false,
//     positionActionsColumn: "last",
//     muiTableHeadCellProps: {
//       sx: (theme) => ({
//         color: "#4a2a7d",
//         background: "#DCDAFF",
//         fontWeight: "normal",
//         fontSize: "10px",
//       }),
//     },
//     renderTopToolbarCustomActions: ({ table }) => (
//       <Box
//         sx={{
//           display: "flex",
//           gap: "16px",
//           padding: "8px",
//           flexWrap: "wrap",
//         }}
//       >
//         <Button
//           //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
//           onClick={handleExportData}
//           startIcon={<FileDownloadIcon />}
//         >
//           Export All Data
//         </Button>
//       </Box>
//     ),
//   });

//   const chartSetting = {
//     yAxis: [
//       {
//         label: "Grams",
//       },
//     ],
//     width: 1000,
//     height: 400,
//     padding: "1rem",
//     sx: {
//       [`.${axisClasses.left} .${axisClasses.label}`]: {
//         transform: "translate(-20px, -20px)",
//       },
//     },
//   };

//   const valueFormatter = (value) => `${value}gm`;

//   // useEffect(() => {
//   //   const optionsVisitorsProfile = {
//   //     series: [70, 30],
//   //     labels: ["Silver", "Gold"],
//   //     colors: ["#C0C0C0", "#FFD700"],
//   //     chart: {
//   //       type: "donut",
//   //       width: "100%",
//   //       height: "350px",
//   //     },
//   //     legend: {
//   //       position: "bottom",
//   //     },
//   //     plotOptions: {
//   //       pie: {
//   //         donut: {
//   //           size: "30%",
//   //         },
//   //       },
//   //     },
//   //   };

//   //   const chartVisitorsProfile = new ApexCharts(
//   //     document.getElementById("chart-visitors-profile"),
//   //     optionsVisitorsProfile
//   //   );

//   //   chartVisitorsProfile.render();

//   //   return () => {
//   //     chartVisitorsProfile.destroy();
//   //   };
//   // }, []);

//   return (
//     <div className="dashboard section">
//       <div class="page-content">
//         <section class="row">
//           {/* <div className="col-12 col-lg-9">
//             <div className="row">
//               <div className="col-6 col-lg-4 col-md-6">
//                 <div className="card" style={{ width: "100%" }}>
//                   <div className="card-body px-4 py-4-5">
//                     <div className="row">
//                       <div className="col-md-4 col-lg-12 col-xl-12 col-xxl-5 d-flex justify-content-start">
//                         <div className="stats-icon purple mb-2">
//                           <img
//                             src={TotalAmount}
//                             alt="Total Amount"
//                             width="100"
//                             className="me-3"
//                           />
//                         </div>
//                       </div>
//                       <div className="col-md-8 col-lg-12 col-xl-12 col-xxl-7">
//                         <h6 className="text-muted font-semibold">
//                           Total Amount
//                         </h6>
//                         <h6 className="font-extrabold mb-0">
//
//                           ₹ {TotalAugmontData.totalAumAmount}
//                         </h6>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </div>

//               <div className="col-6 col-lg-4 col-md-6">
//                 <div className="card" style={{ width: "100%" }}>
//                   <div className="card-body px-4 py-4-5">
//                     <div className="row">
//                       <div className="col-md-4 col-lg-12 col-xl-12 col-xxl-5 d-flex justify-content-start">
//                         <div className="stats-icon blue mb-2">
//                           <img
//                             src={TotalGoldAmount}
//                             alt="Total Gold Amount"
//                             width="100"
//                             className="me-3"
//                           />
//                         </div>
//                       </div>
//                       <div className="col-md-8 col-lg-12 col-xl-12 col-xxl-7">
//                         <h6 className="text-muted font-semibold">
//                           Gold Amount
//                         </h6>
//                         <h6 className="font-extrabold mb-0">
//
//                           ₹ {TotalAugmontData.totalGoldAmount}
//                         </h6>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </div>

//               <div className="col-6 col-lg-4 col-md-6">
//                 <div className="card" style={{ width: "100%" }}>
//                   <div className="card-body px-4 py-4-5">
//                     <div className="row">
//                       <div className="col-md-4 col-lg-12 col-xl-12 col-xxl-5 d-flex justify-content-start">
//                         <div className="stats-icon green mb-2">
//                           <img
//                             src={TotalSilverAmount}
//                             alt="Total Silver Amount"
//                             width="100"
//                             className="me-3"
//                           />
//                         </div>
//                       </div>
//                       <div className="col-md-8 col-lg-12 col-xl-12 col-xxl-7">
//                         <h6 className="text-muted font-semibold">
//                           Silver Amount
//                         </h6>
//                         <h6 className="font-extrabold mb-0">
//
//                           ₹ {TotalAugmontData.totalSilverAmount}
//                         </h6>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </div>

//               <div className="col-6 col-lg-4 col-md-6">
//                 <div className="card" style={{ width: "100%" }}>
//                   <div className="card-body px-4 py-4-5">
//                     <div className="row">
//                       <div className="col-md-4 col-lg-12 col-xl-12 col-xxl-5 d-flex justify-content-start">
//                         <div className="stats-icon red mb-2">
//                           <i className="iconly-boldBookmark"></i>
//                         </div>
//                       </div>
//                       <div className="col-md-8 col-lg-12 col-xl-12 col-xxl-7">
//                         <h6 className="text-muted font-semibold">Saved Post</h6>
//                         <h6 className="font-extrabold mb-0">112</h6>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </div>

//               <div className="col-6 col-lg-4 col-md-6">
//                 <div className="card" style={{ width: "100%" }}>
//                   <div className="card-body px-4 py-4-5">
//                     <div className="row">
//                       <div className="col-md-4 col-lg-12 col-xl-12 col-xxl-5 d-flex justify-content-start">
//                         <div className="stats-icon red mb-2">
//                           <img
//                             src={TotalGoldGrams}
//                             alt="Total Gold Grams"
//                             width="100"
//                             className="me-3"
//                           />
//                         </div>
//                       </div>
//                       <div className="col-md-8 col-lg-12 col-xl-12 col-xxl-7">
//                         <h6 className="text-muted font-semibold">Gold Grams</h6>
//                         <h6 className="font-extrabold mb-0">
//                           {TotalAugmontData.totalGoldGrams}g
//                         </h6>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </div>

//               <div className="col-6 col-lg-4 col-md-6">
//                 <div className="card" style={{ width: "100%" }}>
//                   <div className="card-body px-4 py-4-5">
//                     <div className="row">
//                       <div className="col-md-4 col-lg-12 col-xl-12 col-xxl-5 d-flex justify-content-start">
//                         <div className="stats-icon red mb-2">
//                           <img
//                             src={TotalSilverGrams}
//                             alt="Total Silver Grams"
//                             width="100"
//                             className="me-3"
//                           />
//                         </div>
//                       </div>
//                       <div className="col-md-8 col-lg-12 col-xl-12 col-xxl-7">
//                         <h6 className="text-muted font-semibold">
//                           Silver Grams
//                         </h6>
//                         <h6 className="font-extrabold mb-0">
//                           {TotalAugmontData.totalSilverGrams}g
//                         </h6>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>

//             <div className="row">
//               <div className="col-12">
//                 <div className="card" style={{ width: "100%" }}>
//                   <div className="card-header">
//                     <h4>Total Gold/Silver</h4>
//                   </div>
//                   <div className="card-body">
//                     <div id="chart-profile-visit"></div>
//                   </div>
//                 </div>
//               </div>
//             </div>

//             <div className="row">
//               <div className="col-12 col-xl-4">
//                 <div className="card" style={{ width: "100%" }}>
//                   <div className="card-header">
//                     <h4>Profile Visit</h4>
//                   </div>
//                   <div className="card-body">
//                     <div className="row">
//                       <div className="col-7">
//                         <div className="d-flex align-items-center">
//                           <svg
//                             className="bi text-primary"
//                             width="32"
//                             height="32"
//                             fill="blue"
//                             style={{ width: "10px" }}
//                           >
//                             <use xlinkHref="assets/static/images/bootstrap-icons.svg#circle-fill" />
//                           </svg>
//                           <h5 className="mb-0 ms-3">Europe</h5>
//                         </div>
//                       </div>
//                       <div className="col-5">
//                         <h5 className="mb-0 text-end">862</h5>
//                       </div>
//                       <div className="col-12">
//                         <div id="chart-europe"></div>
//                       </div>
//                     </div>
//                     <div className="row">
//                       <div className="col-7">
//                         <div className="d-flex align-items-center">
//                           <svg
//                             className="bi text-success"
//                             width="32"
//                             height="32"
//                             fill="blue"
//                             style={{ width: "10px" }}
//                           >
//                             <use xlinkHref="assets/static/images/bootstrap-icons.svg#circle-fill" />
//                           </svg>
//                           <h5 className="mb-0 ms-3">America</h5>
//                         </div>
//                       </div>
//                       <div className="col-5">
//                         <h5 className="mb-0 text-end">375</h5>
//                       </div>
//                       <div className="col-12">
//                         <div id="chart-america"></div>
//                       </div>
//                     </div>
//                     <div className="row">
//                       <div className="col-7">
//                         <div className="d-flex align-items-center">
//                           <svg
//                             className="bi text-success"
//                             width="32"
//                             height="32"
//                             fill="blue"
//                             style={{ width: "10px" }}
//                           >
//                             <use xlinkHref="assets/static/images/bootstrap-icons.svg#circle-fill" />
//                           </svg>
//                           <h5 className="mb-0 ms-3">India</h5>
//                         </div>
//                       </div>
//                       <div className="col-5">
//                         <h5 className="mb-0 text-end">625</h5>
//                       </div>
//                       <div className="col-12">
//                         <div id="chart-india"></div>
//                       </div>
//                     </div>
//                     <div className="row">
//                       <div className="col-7">
//                         <div className="d-flex align-items-center">
//                           <svg
//                             className="bi text-danger"
//                             width="32"
//                             height="32"
//                             fill="blue"
//                             style={{ width: "10px" }}
//                           >
//                             <use xlinkHref="assets/static/images/bootstrap-icons.svg#circle-fill" />
//                           </svg>
//                           <h5 className="mb-0 ms-3">Indonesia</h5>
//                         </div>
//                       </div>
//                       <div className="col-5">
//                         <h5 className="mb-0 text-end">1025</h5>
//                       </div>
//                       <div className="col-12">
//                         <div id="chart-indonesia"></div>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </div>

//               <div className="col-12 col-xl-8">
//                 <div className="card" style={{ width: "100%" }}>
//                   <div className="card-header">
//                     <h4>Latest Comments</h4>
//                   </div>
//                   <div className="card-body">
//                     <div className="table-responsive">
//                       <table className="table table-hover table-lg">
//                         <thead>
//                           <tr>
//                             <th>Name</th>
//                             <th>Comment</th>
//                           </tr>
//                         </thead>
//                         <tbody>
//                           <tr>
//                             <td className="col-3">
//                               <div className="d-flex align-items-center">
//                                 <div className="avatar avatar-md">
//                                   <img
//                                     src="assets/static/images/faces/5.jpg"
//                                     alt="avatar"
//                                   />
//                                 </div>
//                                 <p className="font-bold ms-3 mb-0">Si Cantik</p>
//                               </div>
//                             </td>
//                             <td className="col-auto">
//                               <p className="mb-0">
//                                 Congratulations on your graduation!
//                               </p>
//                             </td>
//                           </tr>
//                           <tr>
//                             <td className="col-3">
//                               <div className="d-flex align-items-center">
//                                 <div className="avatar avatar-md">
//                                   <img
//                                     src="assets/static/images/faces/2.jpg"
//                                     alt="avatar"
//                                   />
//                                 </div>
//                                 <p className="font-bold ms-3 mb-0">
//                                   Si Ganteng
//                                 </p>
//                               </div>
//                             </td>
//                             <td className="col-auto">
//                               <p className="mb-0">
//                                 Wow amazing design! Can you make another
//                                 tutorial for this design?
//                               </p>
//                             </td>
//                           </tr>
//                         </tbody>
//                       </table>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>

//             <div className="row">
//               <div className="col-12">
//                 <div className="card" style={{ width: "100%" }}>
//                   <div className="card-header">
//                     <h4>Top Products</h4>
//                   </div>
//                   <div className="card-body">
//                     <div className="row">
//                       <div className="col-4">
//                         <div className="d-flex align-items-center">
//                           <div className="avatar avatar-lg">
//                             <img
//                               src="assets/static/images/products/samsung.jpg"
//                               alt="samsung"
//                             />
//                           </div>
//                           <p className="font-bold ms-3 mb-0">Samsung</p>
//                         </div>
//                       </div>
//                       <div className="col-4">
//                         <div className="d-flex align-items-center">
//                           <div className="avatar avatar-lg">
//                             <img
//                               src="assets/static/images/products/apple.jpg"
//                               alt="apple"
//                             />
//                           </div>
//                           <p className="font-bold ms-3 mb-0">Apple</p>
//                         </div>
//                       </div>
//                       <div className="col-4">
//                         <div className="d-flex align-items-center">
//                           <div className="avatar avatar-lg">
//                             <img
//                               src="assets/static/images/products/sony.jpg"
//                               alt="sony"
//                             />
//                           </div>
//                           <p className="font-bold ms-3 mb-0">Sony</p>
//                         </div>
//                       </div>
//                     </div>
//                     <div className="row mt-4">
//                       <div className="col-4">
//                         <div className="d-flex align-items-center">
//                           <div className="avatar avatar-lg">
//                             <img
//                               src="assets/static/images/products/samsung.jpg"
//                               alt="samsung"
//                             />
//                           </div>
//                           <p className="font-bold ms-3 mb-0">Samsung</p>
//                         </div>
//                       </div>
//                       <div className="col-4">
//                         <div className="d-flex align-items-center">
//                           <div className="avatar avatar-lg">
//                             <img
//                               src="assets/static/images/products/apple.jpg"
//                               alt="apple"
//                             />
//                           </div>
//                           <p className="font-bold ms-3 mb-0">Apple</p>
//                         </div>
//                       </div>
//                       <div className="col-4">
//                         <div className="d-flex align-items-center">
//                           <div className="avatar avatar-lg">
//                             <img
//                               src="assets/static/images/products/sony.jpg"
//                               alt="sony"
//                             />
//                           </div>
//                           <p className="font-bold ms-3 mb-0">Sony</p>
//                         </div>
//                       </div>
//                     </div>
//                     <div className="row mt-4">
//                       <div className="col-4">
//                         <div className="d-flex align-items-center">
//                           <div className="avatar avatar-lg">
//                             <img
//                               src="assets/static/images/products/samsung.jpg"
//                               alt="samsung"
//                             />
//                           </div>
//                           <p className="font-bold ms-3 mb-0">Samsung</p>
//                         </div>
//                       </div>
//                       <div className="col-4">
//                         <div className="d-flex align-items-center">
//                           <div className="avatar avatar-lg">
//                             <img
//                               src="assets/static/images/products/apple.jpg"
//                               alt="apple"
//                             />
//                           </div>
//                           <p className="font-bold ms-3 mb-0">Apple</p>
//                         </div>
//                       </div>
//                       <div className="col-4">
//                         <div className="d-flex align-items-center">
//                           <div className="avatar avatar-lg">
//                             <img
//                               src="assets/static/images/products/sony.jpg"
//                               alt="sony"
//                             />
//                           </div>
//                           <p className="font-bold ms-3 mb-0">Sony</p>
//                         </div>
//                       </div>
//                     </div>
//                     <div className="row mt-4">
//                       <div className="col-4">
//                         <div className="d-flex align-items-center">
//                           <div className="avatar avatar-lg">
//                             <img
//                               src="assets/static/images/products/samsung.jpg"
//                               alt="samsung"
//                             />
//                           </div>
//                           <p className="font-bold ms-3 mb-0">Samsung</p>
//                         </div>
//                       </div>
//                       <div className="col-4">
//                         <div className="d-flex align-items-center">
//                           <div className="avatar avatar-lg">
//                             <img
//                               src="assets/static/images/products/apple.jpg"
//                               alt="apple"
//                             />
//                           </div>
//                           <p className="font-bold ms-3 mb-0">Apple</p>
//                         </div>
//                       </div>
//                       <div className="col-4">
//                         <div className="d-flex align-items-center">
//                           <div className="avatar avatar-lg">
//                             <img
//                               src="assets/static/images/products/sony.jpg"
//                               alt="sony"
//                             />
//                           </div>
//                           <p className="font-bold ms-3 mb-0">Sony</p>
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div> */}
//           {/* <div class="col-12 col-lg-9">
//             <div class="row">
//               <div class="col-6 col-lg-4 col-md-6" style={{ width: "20rem" }}>
//                 <div class="card" style={{ width: "100%" }}>
//                   <div class="card-body px-4 py-4-5">
//                     <div class="row">
//                       <div
//                         class="col-md-4 col-lg-12 col-xl-12 col-xxl-5 d-flex justify-content-start "
//                         style={{ width: "50%" }}
//                       >
//                         <div class="stats-icon purple mb-2">
//                           <img
//                             src={TotalAmount}
//                             alt="Total Amount"
//                             width="100"
//                             className="me-3"
//                           />
//                         </div>
//                       </div>
//                       <div
//                         class="col-md-8 col-lg-12 col-xl-12 col-xxl-7"
//                         style={{ width: "50%" }}
//                       >
//                         <h6 class="text-muted font-semibold">Total Amount</h6>
//                         <h6 class="font-extrabold mb-0">
//                           ₹ {TotalAugmontData.totalAumAmount}
//                         </h6>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//               <div class="col-6 col-lg-4 col-md-6" style={{ width: "20rem" }}>
//                 <div class="card" style={{ width: "100%" }}>
//                   <div class="card-body px-4 py-4-5">
//                     <div class="row">
//                       <div
//                         class="col-md-4 col-lg-12 col-xl-12 col-xxl-5 d-flex justify-content-start "
//                         style={{ width: "50%" }}
//                       >
//                         <div class="stats-icon purple mb-2">
//                           <img
//                             src={TotalGoldAmount}
//                             alt="Total Gold Amount"
//                             width="100"
//                             className="me-3"
//                           />
//                         </div>
//                       </div>
//                       <div
//                         class="col-md-8 col-lg-12 col-xl-12 col-xxl-7"
//                         style={{ width: "50%" }}
//                       >
//                         <h6 class="text-muted font-semibold">Gold Amount</h6>
//                         <h6 class="font-extrabold mb-0">
//                           ₹ {TotalAugmontData.totalGoldAmount}
//                         </h6>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//               <div class="col-6 col-lg-4 col-md-6" style={{ width: "20rem" }}>
//                 <div class="card" style={{ width: "100%" }}>
//                   <div class="card-body px-4 py-4-5">
//                     <div class="row">
//                       <div
//                         class="col-md-4 col-lg-12 col-xl-12 col-xxl-5 d-flex justify-content-start "
//                         style={{ width: "50%" }}
//                       >
//                         <div class="stats-icon purple mb-2">
//                           <img
//                             src={TotalSilverAmount}
//                             alt="Total Silver Amount"
//                             width="100"
//                             className="me-3"
//                           />
//                         </div>
//                       </div>
//                       <div
//                         class="col-md-8 col-lg-12 col-xl-12 col-xxl-7"
//                         style={{ width: "50%" }}
//                       >
//                         <h6 class="text-muted font-semibold">Silver Amount</h6>
//                         <h6 class="font-extrabold mb-0">
//                           ₹ {TotalAugmontData.totalSilverAmount}
//                         </h6>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//               <div class="col-6 col-lg-4 col-md-6" style={{ width: "20rem" }}>
//                 <div class="card" style={{ width: "100%" }}>
//                   <div class="card-body px-4 py-4-5">
//                     <div class="row">
//                       <div
//                         class="col-md-4 col-lg-12 col-xl-12 col-xxl-5 d-flex justify-content-start "
//                         style={{ width: "50%" }}
//                       >
//                         <div class="stats-icon purple mb-2">
//                           <img
//                             src={TotalGoldGrams}
//                             alt="Total Gold Grams"
//                             width="100"
//                             className="me-3"
//                           />
//                         </div>
//                       </div>
//                       <div
//                         class="col-md-8 col-lg-12 col-xl-12 col-xxl-7"
//                         style={{ width: "50%" }}
//                       >
//                         <h6 class="text-muted font-semibold">Gold Grams</h6>
//                         <h6 class="font-extrabold mb-0">
//                           {TotalAugmontData.totalGoldGrams}g
//                         </h6>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//               <div class="col-6 col-lg-4 col-md-6" style={{ width: "20rem" }}>
//                 <div class="card" style={{ width: "100%" }}>
//                   <div class="card-body px-4 py-4-5">
//                     <div class="row">
//                       <div
//                         class="col-md-4 col-lg-12 col-xl-12 col-xxl-5 d-flex justify-content-start "
//                         style={{ width: "50%" }}
//                       >
//                         <div class="stats-icon purple mb-2">
//                           <img
//                             src={TotalSilverGrams}
//                             alt="Total Silver Grams"
//                             width="100"
//                             className="me-3"
//                           />
//                         </div>
//                       </div>
//                       <div
//                         class="col-md-8 col-lg-12 col-xl-12 col-xxl-7"
//                         style={{ width: "50%" }}
//                       >
//                         <h6 class="text-muted font-semibold">Silver Grams</h6>
//                         <h6 class="font-extrabold mb-0">
//                           {TotalAugmontData.totalSilverGrams}g
//                         </h6>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//             <div class="row">
//               <div class="col-12">
//                 <div class="card" style={{ width: "100%" }}>
//                   <div class="card-header">
//                     <h4>Profile Visit</h4>
//                   </div>
//                   <div class="card-body">
//                     <div id="chart-profile-visit"></div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//             <div class="row">
//               <div class="col-12 col-xl-4">
//                 <div class="card" style={{ width: "100%" }}>
//                   <div class="card-header">
//                     <h4>Profile Visit</h4>
//                   </div>
//                   <div class="card-body">
//                     <div class="row">
//                       <div class="col-7">
//                         <div class="d-flex align-items-center">
//                           <svg
//                             class="bi text-primary"
//                             width="32"
//                             height="32"
//                             fill="blue"
//                             style={{ width: "10px" }}
//                           >
//                             <use xlinkHref="assets/static/images/bootstrap-icons.svg#circle-fill" />
//                           </svg>
//                           <h5 class="mb-0 ms-3">Europe</h5>
//                         </div>
//                       </div>
//                       <div class="col-5">
//                         <h5 class="mb-0 text-end">862</h5>
//                       </div>
//                       <div class="col-12">
//                         <div id="chart-europe"></div>
//                       </div>
//                     </div>
//                     <div class="row">
//                       <div class="col-7">
//                         <div class="d-flex align-items-center">
//                           <svg
//                             class="bi text-success"
//                             width="32"
//                             height="32"
//                             fill="blue"
//                             style={{ width: "10px" }}
//                           >
//                             <use xlinkHref="assets/static/images/bootstrap-icons.svg#circle-fill" />
//                           </svg>
//                           <h5 class="mb-0 ms-3">America</h5>
//                         </div>
//                       </div>
//                       <div class="col-5">
//                         <h5 class="mb-0 text-end">375</h5>{" "}
//                       </div>
//                       <div class="col-12">
//                         <div id="chart-america"></div>
//                       </div>
//                     </div>
//                     <div class="row">
//                       <div class="col-7">
//                         <div class="d-flex align-items-center">
//                           <svg
//                             class="bi text-success"
//                             width="32"
//                             height="32"
//                             fill="blue"
//                             style={{ width: "10px" }}
//                           >
//                             <use xlinkHref="assets/static/images/bootstrap-icons.svg#circle-fill" />
//                           </svg>
//                           <h5 class="mb-0 ms-3">India</h5>
//                         </div>
//                       </div>
//                       <div class="col-5">
//                         <h5 class="mb-0 text-end">625</h5>
//                       </div>
//                       <div class="col-12">
//                         <div id="chart-india"></div>
//                       </div>
//                     </div>
//                     <div class="row">
//                       <div class="col-7">
//                         <div class="d-flex align-items-center">
//                           <svg
//                             class="bi text-danger"
//                             width="32"
//                             height="32"
//                             fill="blue"
//                             style={{ width: "10px" }}
//                           >
//                             <use xlinkHref="assets/static/images/bootstrap-icons.svg#circle-fill" />
//                           </svg>
//                           <h5 class="mb-0 ms-3">Indonesia</h5>
//                         </div>
//                       </div>
//                       <div class="col-5">
//                         <h5 class="mb-0 text-end">1025</h5>
//                       </div>
//                       <div class="col-12">
//                         <div id="chart-indonesia"></div>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//               <div class="col-12 col-xl-8">
//                 <div class="card" style={{ width: "100%" }}>
//                   <div class="card-header">
//                     <h4>Latest Comments</h4>
//                   </div>
//                   <div class="card-body">
//                     <div class="table-responsive">
//                       <table class="table table-hover table-lg">
//                         <thead>
//                           <tr>
//                             <th>Name</th>
//                             <th>Comment</th>
//                           </tr>
//                         </thead>
//                         <tbody>
//                           <tr>
//                             <td class="col-3">
//                               <div class="d-flex align-items-center">
//                                 <div class="avatar avatar-md">
//                                   <img src="assets/static/images/faces/5.jpg" />
//                                 </div>
//                                 <p class="font-bold ms-3 mb-0">Si Cantik</p>
//                               </div>
//                             </td>
//                             <td class="col-auto">
//                               <p class=" mb-0">
//                                 Congratulations on your graduation!
//                               </p>
//                             </td>
//                           </tr>
//                           <tr>
//                             <td class="col-3">
//                               <div class="d-flex align-items-center">
//                                 <div class="avatar avatar-md">
//                                   <img src="assets/static/images/faces/2.jpg" />
//                                 </div>
//                                 <p class="font-bold ms-3 mb-0">Si Ganteng</p>
//                               </div>
//                             </td>
//                             <td class="col-auto">
//                               <p class=" mb-0">
//                                 Wow amazing design! Can you make another
//                                 tutorial for this design?
//                               </p>
//                             </td>
//                           </tr>
//                           <tr>
//                             <td class="col-3">
//                               <div class="d-flex align-items-center">
//                                 <div class="avatar avatar-md">
//                                   <img src="assets/static/images/faces/8.jpg" />
//                                 </div>
//                                 <p class="font-bold ms-3 mb-0">Singh Eknoor</p>
//                               </div>
//                             </td>
//                             <td class="col-auto">
//                               <p class=" mb-0">Amazing work! Keep it up.</p>
//                             </td>
//                           </tr>
//                           <tr>
//                             <td class="col-3">
//                               <div class="d-flex align-items-center">
//                                 <div class="avatar avatar-md">
//                                   <img src="assets/static/images/faces/9.jpg" />
//                                 </div>
//                                 <p class="font-bold ms-3 mb-0">Deepika Yadav</p>
//                               </div>
//                             </td>
//                             <td class="col-auto">
//                               <p class=" mb-0">
//                                 Great job! Looking forward to more.
//                               </p>
//                             </td>
//                           </tr>
//                           <tr>
//                             <td class="col-3">
//                               <div class="d-flex align-items-center">
//                                 <div class="avatar avatar-md">
//                                   <img src="assets/static/images/faces/10.jpg" />
//                                 </div>
//                                 <p class="font-bold ms-3 mb-0">Rohit Sharma</p>
//                               </div>
//                             </td>
//                             <td class="col-auto">
//                               <p class=" mb-0">
//                                 This is very helpful, thank you!
//                               </p>
//                             </td>
//                           </tr>
//                           <tr>
//                             <td class="col-3">
//                               <div class="d-flex align-items-center">
//                                 <div class="avatar avatar-md">
//                                   <img src="assets/static/images/faces/11.jpg" />
//                                 </div>
//                                 <p class="font-bold ms-3 mb-0">Arnav Gupta</p>
//                               </div>
//                             </td>
//                             <td class="col-auto">
//                               <p class=" mb-0">
//                                 I appreciate the effort, keep it up!
//                               </p>
//                             </td>
//                           </tr>
//                           <tr>
//                             <td class="col-3">
//                               <div class="d-flex align-items-center">
//                                 <div class="avatar avatar-md">
//                                   <img src="assets/static/images/faces/12.jpg" />
//                                 </div>
//                                 <p class="font-bold ms-3 mb-0">Nisha Patel</p>
//                               </div>
//                             </td>
//                             <td class="col-auto">
//                               <p class=" mb-0">
//                                 Fantastic work, really impressive!
//                               </p>
//                             </td>
//                           </tr>
//                           <tr>
//                             <td class="col-3">
//                               <div class="d-flex align-items-center">
//                                 <div class="avatar avatar-md">
//                                   <img src="assets/static/images/faces/13.jpg" />
//                                 </div>
//                                 <p class="font-bold ms-3 mb-0">Arjun Reddy</p>
//                               </div>
//                             </td>
//                             <td class="col-auto">
//                               <p class=" mb-0">
//                                 Absolutely loved this project.
//                               </p>
//                             </td>
//                           </tr>
//                           <tr>
//                             <td class="col-3">
//                               <div class="d-flex align-items-center">
//                                 <div class="avatar avatar-md">
//                                   <img src="assets/static/images/faces/14.jpg" />
//                                 </div>
//                                 <p class="font-bold ms-3 mb-0">Riya Sen</p>
//                               </div>
//                             </td>
//                             <td class="col-auto">
//                               <p class=" mb-0">
//                                 Can you share more details about this?
//                               </p>
//                             </td>
//                           </tr>
//                           <tr>
//                             <td class="col-3">
//                               <div class="d-flex align-items-center">
//                                 <div class="avatar avatar-md">
//                                   <img src="assets/static/images/faces/15.jpg" />
//                                 </div>
//                                 <p class="font-bold ms-3 mb-0">Kabir Khan</p>
//                               </div>
//                             </td>
//                             <td class="col-auto">
//                               <p class=" mb-0">
//                                 This is really inspiring, thank you!
//                               </p>
//                             </td>
//                           </tr>
//                           <tr>
//                             <td class="col-3">
//                               <div class="d-flex align-items-center">
//                                 <div class="avatar avatar-md">
//                                   <img src="assets/static/images/faces/16.jpg" />
//                                 </div>
//                                 <p class="font-bold ms-3 mb-0">Aditya Roy</p>
//                               </div>
//                             </td>
//                             <td class="col-auto">
//                               <p class=" mb-0">
//                                 Amazing, keep up the great work!
//                               </p>
//                             </td>
//                           </tr>
//                           <tr>
//                             <td class="col-3">
//                               <div class="d-flex align-items-center">
//                                 <div class="avatar avatar-md">
//                                   <img src="assets/static/images/faces/17.jpg" />
//                                 </div>
//                                 <p class="font-bold ms-3 mb-0">Priya Gupta</p>
//                               </div>
//                             </td>
//                             <td class="col-auto">
//                               <p class=" mb-0">
//                                 This project is really well done.
//                               </p>
//                             </td>
//                           </tr>
//                           <tr>
//                             <td class="col-3">
//                               <div class="d-flex align-items-center">
//                                 <div class="avatar avatar-md">
//                                   <img src="assets/static/images/faces/18.jpg" />
//                                 </div>
//                                 <p class="font-bold ms-3 mb-0">Ankit Sharma</p>
//                               </div>
//                             </td>
//                             <td class="col-auto">
//                               <p class=" mb-0">Great insights, thank you!</p>
//                             </td>
//                           </tr>
//                           <tr>
//                             <td class="col-3">
//                               <div class="d-flex align-items-center">
//                                 <div class="avatar avatar-md">
//                                   <img src="assets/static/images/faces/19.jpg" />
//                                 </div>
//                                 <p class="font-bold ms-3 mb-0">Tanvi Mehta</p>
//                               </div>
//                             </td>
//                             <td class="col-auto">
//                               <p class=" mb-0">
//                                 I learned a lot from this, thanks!
//                               </p>
//                             </td>
//                           </tr>
//                           <tr>
//                             <td class="col-3">
//                               <div class="d-flex align-items-center">
//                                 <div class="avatar avatar-md">
//                                   <img src="assets/static/images/faces/20.jpg" />
//                                 </div>
//                                 <p class="font-bold ms-3 mb-0">Aryan Joshi</p>
//                               </div>
//                             </td>
//                             <td class="col-auto">
//                               <p class=" mb-0">
//                                 Awesome work, very informative.
//                               </p>
//                             </td>
//                           </tr>
//                           <tr>
//                             <td class="col-3">
//                               <div class="d-flex align-items-center">
//                                 <div class="avatar avatar-md">
//                                   <img src="assets/static/images/faces/21.jpg" />
//                                 </div>
//                                 <p class="font-bold ms-3 mb-0">Kavya Rao</p>
//                               </div>
//                             </td>
//                             <td class="col-auto">
//                               <p class=" mb-0">
//                                 Very well put together, thanks!
//                               </p>
//                             </td>
//                           </tr>
//                           <tr>
//                             <td class="col-3">
//                               <div class="d-flex align-items-center">
//                                 <div class="avatar avatar-md">
//                                   <img src="assets/static/images/faces/22.jpg" />
//                                 </div>
//                                 <p class="font-bold ms-3 mb-0">Sana Ali</p>
//                               </div>
//                             </td>
//                             <td class="col-auto">
//                               <p class=" mb-0">
//                                 I found this really helpful, thanks!
//                               </p>
//                             </td>
//                           </tr>
//                           <tr>
//                             <td class="col-3">
//                               <div class="d-flex align-items-center">
//                                 <div class="avatar avatar-md">
//                                   <img src="assets/static/images/faces/23.jpg" />
//                                 </div>
//                                 <p class="font-bold ms-3 mb-0">Vihaan Patel</p>
//                               </div>
//                             </td>
//                             <td class="col-auto">
//                               <p class=" mb-0">Great work, very insightful!</p>
//                             </td>
//                           </tr>
//                           <tr>
//                             <td class="col-3">
//                               <div class="d-flex align-items-center">
//                                 <div class="avatar avatar-md">
//                                   <img src="assets/static/images/faces/24.jpg" />
//                                 </div>
//                                 <p class="font-bold ms-3 mb-0">Aditi Singh</p>
//                               </div>
//                             </td>
//                             <td class="col-auto">
//                               <p class=" mb-0">
//                                 This is really well explained.
//                               </p>
//                             </td>
//                           </tr>
//                           <tr>
//                             <td class="col-3">
//                               <div class="d-flex align-items-center">
//                                 <div class="avatar avatar-md">
//                                   <img src="assets/static/images/faces/25.jpg" />
//                                 </div>
//                                 <p class="font-bold ms-3 mb-0">Rohan Verma</p>
//                               </div>
//                             </td>
//                             <td class="col-auto">
//                               <p class=" mb-0">Fantastic, very detailed.</p>
//                             </td>
//                           </tr>
//                           <tr>
//                             <td class="col-3">
//                               <div class="d-flex align-items-center">
//                                 <div class="avatar avatar-md">
//                                   <img src="assets/static/images/faces/26.jpg" />
//                                 </div>
//                                 <p class="font-bold ms-3 mb-0">Meera Desai</p>
//                               </div>
//                             </td>
//                             <td class="col-auto">
//                               <p class=" mb-0">Really enjoyed this project.</p>
//                             </td>
//                           </tr>
//                         </tbody>
//                       </table>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div> */}

//           <div class="col-12 col-lg-3">
//             {/* <div class="card" style={{ width: "100%" }}>
//               <div class="card-body py-4 px-4">
//                 <div class="d-flex align-items-center">
//                   <div class="avatar avatar-xl">
//                     <img src="assets/static/images/faces/1.jpg" alt="Face 1" />
//                   </div>
//                   <div class="ms-3 name">
//                     <h5 class="font-bold">John Duck</h5>
//                     <h6 class="text-muted mb-0">@johnducky</h6>
//                   </div>
//                 </div>
//               </div>
//             </div>
//             <div class="card" style={{ width: "100%" }}>
//               <div class="card-header">
//                 <h4>Recent Messages</h4>
//               </div>
//               <div class="card-content pb-4">
//                 <div class="recent-message d-flex px-4 py-3">
//                   <div class="avatar avatar-lg">
//                     <img src="assets/static/images/faces/4.jpg" />
//                   </div>
//                   <div class="name ms-4">
//                     <h5 class="mb-1">Hank Schrader</h5>
//                     <h6 class="text-muted mb-0">@johnducky</h6>
//                   </div>
//                 </div>
//                 <div class="recent-message d-flex px-4 py-3">
//                   <div class="avatar avatar-lg">
//                     <img src="assets/static/images/faces/5.jpg" />
//                   </div>
//                   <div class="name ms-4">
//                     <h5 class="mb-1">Dean Winchester</h5>
//                     <h6 class="text-muted mb-0">@imdean</h6>
//                   </div>
//                 </div>
//                 <div class="recent-message d-flex px-4 py-3">
//                   <div class="avatar avatar-lg">
//                     <img src="assets/static/images/faces/1.jpg" />
//                   </div>
//                   <div class="name ms-4">
//                     <h5 class="mb-1">John Dodol</h5>
//                     <h6 class="text-muted mb-0">@dodoljohn</h6>
//                   </div>
//                 </div>
//                 <div class="px-4">
//                   <button class="btn btn-block btn-xl btn-outline-primary font-bold mt-3">
//                     Start Conversation
//                   </button>
//                 </div>
//               </div>
//             </div> */}
//             {/* <div class="card" style={{ width: "100%" }}>
//               <div class="card-header">
//                 <h4>Visitors Profile</h4>
//               </div>
//               <div class="card-body">
//                 <div id="chart-visitors-profile"></div>
//               </div>
//             </div> */}
//           </div>
//         </section>
//       </div>
//       <Container fluid>
//         <Row style={{ margin: "0px -14px 0px -21px" }}>
//           {/* <div class="col-6 col-lg-3 col-md-6" style={{ width: "20rem" }}>
//             <div class="card" style={{ width: "100%" }}>
//               <div class="card-body px-4 py-4-5">
//                 <div class="row">
//                   <div
//                     class="col-md-4 col-lg-12 col-xl-12 col-xxl-5 d-flex justify-content-start "
//                     style={{ width: "50%" }}
//                   >
//                     <div class="stats-icon purple mb-2">
//                       <img
//                         src={TotalAmount}
//                         alt="Total Amount"
//                         width="100"
//                         className="me-3"
//                       />
//                     </div>
//                   </div>
//                   <div
//                     class="col-md-8 col-lg-12 col-xl-12 col-xxl-7"
//                     style={{ width: "50%" }}
//                   >
//                     <h6 class="text-muted font-semibold">Total Amount</h6>
//                     <h6 class="font-extrabold mb-0">
//                       ₹ {TotalAugmontData.totalAumAmount}
//                     </h6>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//           <div class="col-6 col-lg-3 col-md-6" style={{ width: "20rem" }}>
//             <div class="card" style={{ width: "100%" }}>
//               <div class="card-body px-4 py-4-5">
//                 <div class="row">
//                   <div
//                     class="col-md-4 col-lg-12 col-xl-12 col-xxl-5 d-flex justify-content-start "
//                     style={{ width: "50%" }}
//                   >
//                     <div class="stats-icon purple mb-2">
//                       <img
//                         src={TotalGoldAmount}
//                         alt="Total Gold Amount"
//                         width="100"
//                         className="me-3"
//                       />
//                     </div>
//                   </div>
//                   <div
//                     class="col-md-8 col-lg-12 col-xl-12 col-xxl-7"
//                     style={{ width: "50%" }}
//                   >
//                     <h6 class="text-muted font-semibold">Gold Amount</h6>
//                     <h6 class="font-extrabold mb-0">
//                       ₹ {TotalAugmontData.totalGoldAmount}
//                     </h6>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//           <div class="col-6 col-lg-3 col-md-6" style={{ width: "20rem" }}>
//             <div class="card" style={{ width: "100%" }}>
//               <div class="card-body px-4 py-4-5">
//                 <div class="row">
//                   <div
//                     class="col-md-4 col-lg-12 col-xl-12 col-xxl-5 d-flex justify-content-start "
//                     style={{ width: "50%" }}
//                   >
//                     <div class="stats-icon purple mb-2">
//                       <img
//                         src={TotalSilverAmount}
//                         alt="Total Silver Amount"
//                         width="100"
//                         className="me-3"
//                       />
//                     </div>
//                   </div>
//                   <div
//                     class="col-md-8 col-lg-12 col-xl-12 col-xxl-7"
//                     style={{ width: "50%" }}
//                   >
//                     <h6 class="text-muted font-semibold">Silver Amount</h6>
//                     <h6 class="font-extrabold mb-0">
//                       ₹ {TotalAugmontData.totalSilverAmount}
//                     </h6>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//           <div class="col-6 col-lg-3 col-md-6" style={{ width: "20rem" }}>
//             <div class="card" style={{ width: "100%" }}>
//               <div class="card-body px-4 py-4-5">
//                 <div class="row">
//                   <div
//                     class="col-md-4 col-lg-12 col-xl-12 col-xxl-5 d-flex justify-content-start "
//                     style={{ width: "50%" }}
//                   >
//                     <div class="stats-icon purple mb-2">
//                       <img
//                         src={TotalGoldGrams}
//                         alt="Total Gold Grams"
//                         width="100"
//                         className="me-3"
//                       />
//                     </div>
//                   </div>
//                   <div
//                     class="col-md-8 col-lg-12 col-xl-12 col-xxl-7"
//                     style={{ width: "50%" }}
//                   >
//                     <h6 class="text-muted font-semibold">Gold Grams</h6>
//                     <h6 class="font-extrabold mb-0">
//                       {TotalAugmontData.totalGoldGrams}g
//                     </h6>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//           <div class="col-6 col-lg-3 col-md-6" style={{ width: "20rem" }}>
//             <div class="card" style={{ width: "100%" }}>
//               <div class="card-body px-4 py-4-5">
//                 <div class="row">
//                   <div
//                     class="col-md-4 col-lg-12 col-xl-12 col-xxl-5 d-flex justify-content-start "
//                     style={{ width: "50%" }}
//                   >
//                     <div class="stats-icon purple mb-2">
//                       <img
//                         src={TotalSilverGrams}
//                         alt="Total Silver Grams"
//                         width="100"
//                         className="me-3"
//                       />
//                     </div>
//                   </div>
//                   <div
//                     class="col-md-8 col-lg-12 col-xl-12 col-xxl-7"
//                     style={{ width: "50%" }}
//                   >
//                     <h6 class="text-muted font-semibold">Silver Grams</h6>
//                     <h6 class="font-extrabold mb-0">
//                       {TotalAugmontData.totalSilverGrams}g
//                     </h6>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div> */}
//           <Col xs={12} md={6} lg={4}>
//             <Card className="mb-1" style={{ width: "97%", height: "80%" }}>
//               <Card.Body className="d-flex">
//                 <img
//                   src={TotalAmount}
//                   alt="Total Amount"
//                   width="100"
//                   className="me-3"
//                 />
//                 <div>
//                   <Typography variant="h6">Total Amount</Typography>
//                   <Typography variant="h6">
//                     ₹ {TotalAugmontData.totalAumAmount}
//                   </Typography>
//                 </div>
//               </Card.Body>
//             </Card>
//           </Col>
//           <Col xs={12} md={6} lg={4}>
//             <Card className="mb-4" style={{ width: "97%", height: "80%" }}>
//               <Card.Body className="d-flex">
//                 <img
//                   src={TotalGoldAmount}
//                   alt="Total Gold Amount"
//                   width="100"
//                   className="me-3"
//                 />
//                 <div>
//                   <Typography variant="h6">Gold Amount</Typography>
//                   <Typography variant="h6">
//                     ₹ {TotalAugmontData.totalGoldAmount}
//                   </Typography>
//                 </div>
//               </Card.Body>
//             </Card>
//           </Col>
//           <Col xs={12} md={6} lg={4}>
//             <Card className="mb-4" style={{ width: "97%", height: "80%" }}>
//               <Card.Body className="d-flex">
//                 <img
//                   src={TotalSilverAmount}
//                   alt="Total Silver Amount"
//                   width="100"
//                   className="me-3"
//                 />
//                 <div>
//                   <Typography variant="h6">Silver Amount</Typography>
//                   <Typography variant="h6">
//                     ₹ {TotalAugmontData.totalSilverAmount}
//                   </Typography>
//                 </div>
//               </Card.Body>
//             </Card>
//           </Col>
//           <Col xs={12} md={6} lg={4}>
//             <Card className="mb-4" style={{ width: "97%", height: "80%" }}>
//               <Card.Body className="d-flex">
//                 <img
//                   src={TotalGoldGrams}
//                   alt="Total Gold Grams"
//                   width="100"
//                   className="me-3"
//                 />
//                 <div>
//                   <Typography variant="h6">Gold Grams</Typography>
//                   <Typography variant="h6">
//                     {TotalAugmontData.totalGoldGrams}g
//                   </Typography>
//                 </div>
//               </Card.Body>
//             </Card>
//           </Col>
//           <Col xs={12} md={6} lg={4}>
//             <Card className="mb-4" style={{ width: "97%", height: "80%" }}>
//               <Card.Body className="d-flex">
//                 <img
//                   src={TotalSilverGrams}
//                   alt="Total Silver Grams"
//                   width="100"
//                   className="me-3"
//                 />
//                 <div>
//                   <Typography variant="h6">Silver Grams</Typography>
//                   <Typography variant="h6">
//                     {TotalAugmontData.totalSilverGrams}g
//                   </Typography>
//                 </div>
//               </Card.Body>
//             </Card>
//           </Col>
//         </Row>
//         <Row className="mb-4 card" style={{ width: "100%", marginLeft: "1px" }}>
//           <Col style={{ display: "flex", justifyContent: "center" }}>
//             <BarChart
//               dataset={graphdata}
//               xAxis={[{ scaleType: "band", dataKey: "month" }]}
//               yAxis={{ dataKey: "year", scaleType: "linear" }}
//               series={[
//                 {
//                   dataKey: "gold",
//                   label: "Gold",
//                   color: "gold",
//                   valueFormatter,
//                 },
//                 {
//                   dataKey: "silver",
//                   label: "Silver",
//                   color: "silver",
//                   valueFormatter,
//                 },
//               ]}
//               {...chartSetting}
//             />
//           </Col>
//         </Row>
//         <Row>
//           <Col xs={12}>
//             <h2 className="TABLE_HEADING">Top Clients</h2>
//             <MaterialReactTable table={table} />
//           </Col>
//         </Row>
//       </Container>
//     </div>
//   );
// }

// export default Dashboard;
