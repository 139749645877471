import React from "react";
import "./offers.css";
import offerIcon from "../assets/landing-page/briefcase.png";
import spotGold from "../assets/landing-page/spot-gold.png";
import goldIcon from "../assets/landing-page/Gold-icon.png";
import silverIcon from "../assets/landing-page/Silver-icon.png";
import fdIcon from "../assets/landing-page/almera_gold.png";
import { Link } from "react-router-dom";

const offeringsData = [
  {
    image: goldIcon, // Update the path as per your image location
    title: "Digital Gold / Silver",
    description: "Buy and Sell Gold & Silver digitally starting from Rs. 500",
    subDescription: "Secure, insured storage accessible via our intuitive app",
    buttonText: "Register Now",
    links: "https://online.batuk.in/",
  },
  {
    image: silverIcon, // Update the path as per your image location
    title: "Gold SIP",
    description: "Regular contributions to build your gold savings",
    subDescription:
      "Benefit from cost averaging and align with long-term financial goals",
    buttonText: "Register Now",
    links: "https://online.batuk.in/",
  },
  {
    image: fdIcon, // Update the path as per your image location
    title: "Gold FD",
    description: "Earn guaranteed returns with interest rates",
    subDescription: "Flexible tenures and insured deposits for added security",
    buttonText: "Register Now",
    links: "https://online.batuk.in/",
  },
  {
    image: spotGold, // Update the path as per your image location
    title: "Spot Gold",
    description:
      "High-quality certified gold coins, bars, and stunning jewellery",
    subDescription:
      "Delivered securely and insured, cheaper than market prices",
    buttonText: "Register Now",
    links: "https://online.batuk.in/",
  },
];

const Offers = () => {
  return (
    <div className="offerings-container">
      <div className="offerings-header">
        <img src={offerIcon} alt="Offerings Icon" className="offerings-icon" />
        <h2 className="offerings-title">Batuk’s Offerings</h2>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "3rem",
          marginBottom: "3rem",
        }}
      >
        <div className="cards-container">
          {offeringsData.map((offering, index) => (
            <div className="landing-card" key={index}>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <img
                  src={offering.image}
                  alt={offering.title}
                  className="card-icon"
                />
              </div>
              <h3 className="card-title">{offering.title}</h3>
              <p className="card-description">{offering.description}</p>
              <p className="card-sub-description">{offering.subDescription}</p>
              <a
                href={offering.links}
                target="_blank"
                rel="noopener noreferrer"
                className="card-button"
              >
                {offering.buttonText}
              </a>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Offers;
