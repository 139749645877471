import React from "react";
import Navbar1 from "../../components/Navbar";
import PartnerLanding from "../../landing_page/PartnerLanding";
import Offers from "../../landing_page/Offers";
import OurPartner from "../../landing_page/OurPartner";
import Footer from "../../landing_page/Footer";

const Home = () => {
  return (
    <>
      <Navbar1 />
      <PartnerLanding />
      <Offers />
      <OurPartner />
      <Footer />
    </>
  );
};

export default Home;
