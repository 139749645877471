import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import Logo from "../assets/images/Batuk Logo.png";
import "../assets/css/navbar.css";

const Navbar1 = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  return (
    <nav className="navbar">
      <div className="logo">
        <NavLink to="/">
          <img
            src={Logo}
            width="200"
            height="auto"
            className="d-inline-block align-top"
            alt="LOGO"
          />
        </NavLink>
      </div>
      <div className="hamburger" onClick={toggleMenu}>
        <div className="line"></div>
        <div className="line"></div>
        <div className="line"></div>
      </div>
      <ul className={`nav-links ${isOpen ? "open" : ""}`}>
        <li>
          <NavLink
            to="/home"
            className={({ isActive }) => (isActive ? "active-link" : "")}
          >
            Home
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/blog"
            className={({ isActive }) => (isActive ? "active-link" : "")}
          >
            Blog
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/about"
            className={({ isActive }) => (isActive ? "active-link" : "")}
          >
            About
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/contact"
            className={({ isActive }) => (isActive ? "active-link" : "")}
          >
            Contact
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/faq"
            className={({ isActive }) => (isActive ? "active-link" : "")}
          >
            Faq
          </NavLink>
        </li>

        <div className="auth-buttons-toggle">
          <div
            className="login"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <NavLink
              to="/login"
              className="active-link"
              style={{ fontSize: "23px" }}
            >
              Login
            </NavLink>
          </div>
          <div className="dropdown" onClick={toggleDropdown}>
            <div className="register active-link" style={{ fontSize: "23px" }}>
              Join as Partner
            </div>
            {isDropdownOpen && (
              <div className="dropdown-menu">
                <NavLink
                  to="/register"
                  className="dropdown-item"
                  activeClassName="active-link"
                >
                  Individual Partner
                </NavLink>
                <NavLink
                  to="/indRegister"
                  className="dropdown-item"
                  activeClassName="active-link"
                >
                  Entity Partner
                </NavLink>
              </div>
            )}
          </div>
        </div>
      </ul>
      <div className="auth-buttons">
        <div className="login" style={{ fontSize: "23px" }}>
          <NavLink
            to="/login"
            className={({ isActive }) =>
              isActive ? "active-link" : "login-color"
            }
            style={{ padding: "0px 6px 0px 0px" }}
          >
            Login
          </NavLink>
        </div>
        <div className="dropdown" onClick={toggleDropdown}>
          <div className="register" style={{ fontSize: "23px" }}>
            Join as Partner
          </div>
          {isDropdownOpen && (
            <div className="dropdown-menu">
              <NavLink
                to="/register"
                className="dropdown-item"
                activeClassName="active-link"
              >
                Individual Partner
              </NavLink>
              <NavLink
                to="/indRegister"
                className="dropdown-item"
                activeClassName="active-link"
              >
                Entity Partner
              </NavLink>
            </div>
          )}
        </div>
      </div>
    </nav>
  );
};

export default Navbar1;

// import React, { useState } from "react";
// import Logo from "../assets/images/Batuk Logo.png";
// import "../assets/css/navbar.css";
// import { Link } from "react-router-dom";

// const Navbar1 = () => {
//   const [isOpen, setIsOpen] = useState(false);
//   const [isDropdownOpen, setIsDropdownOpen] = useState(false);

//   const toggleMenu = () => {
//     setIsOpen(!isOpen);
//   };

//   const toggleDropdown = () => {
//     setIsDropdownOpen(!isDropdownOpen);
//   };

//   return (
//     <nav className="navbar">
//       <div className="logo">
//         <Link to="/">
//           <img
//             src={Logo}
//             width="200"
//             height="auto"
//             className="d-inline-block align-top"
//             alt="LOGO"
//           />
//         </Link>
//       </div>
//       <div className="hamburger" onClick={toggleMenu}>
//         <div className="line"></div>
//         <div className="line"></div>
//         <div className="line"></div>
//       </div>
//       <ul className={`nav-links ${isOpen ? "open" : ""}`}>
//         <li>
//           <Link to="/home">Home</Link>
//         </li>
//         <li>
//           <Link to="/blog">Blog</Link>
//         </li>
//         <li>
//           <Link to="/about">About</Link>
//         </li>
//         <li>
//           <Link to="/contact">Contact</Link>
//         </li>
//         <li>
//           <Link to="/faq">Faq</Link>
//         </li>

//         <div className="auth-buttons-toggle">
//           <button
//             className="login"
//             style={{
//               display: "flex",
//               justifyContent: "center",
//               alignItems: "center",
//             }}
//           >
//             <Link to="/login" className="login-link-color">
//               Login
//             </Link>
//           </button>
//           <div className="dropdown" onClick={toggleDropdown}>
//             <button className="register">Join as Partner</button>
//             {isDropdownOpen && (
//               <div className="dropdown-menu">
//                 <Link to="/register" className="dropdown-item">
//                   Individual Partner
//                 </Link>
//                 <Link to="/indRegister" className="dropdown-item">
//                   Entity Partner
//                 </Link>
//               </div>
//             )}
//           </div>
//         </div>
//       </ul>
//       <div className="auth-buttons">
//         <button className="login" style={{ margin: "0px" }}>
//           <Link to="/login" className="login-link-color">
//             Login
//           </Link>
//         </button>
//         <div className="dropdown" onClick={toggleDropdown}>
//           <button className="register" style={{ margin: "0px" }}>
//             Join as Partner
//           </button>
//           {isDropdownOpen && (
//             <div className="dropdown-menu">
//               <Link to="/register" className="dropdown-item">
//                 Individual Partner
//               </Link>
//               <Link to="/indRegister" className="dropdown-item">
//                 Entity Partner
//               </Link>
//             </div>
//           )}
//         </div>
//       </div>
//     </nav>
//   );
// };

// export default Navbar1;
