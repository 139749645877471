import React, { useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Container,
  Grid,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Navbar1 from "../../components/Navbar";
import Footer from "../../landing_page/Footer";
import "../../assets/css/faq.css";
import faqs from "./faqData";

const Faq = () => {
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <>
      <Navbar1 />
      <div>
        <div
          style={{
            position: "relative",
          }}
        >
          <div class="faq-banner">
            <h1 style={{ color: "white" }}>Frequently Asked Questions</h1>
          </div>
        </div>
        <div className="faq_box">
          <h1 style={{ marginBottom: "30px" }}>FAQ's</h1>
          <div style={{ display: "flex", flexDirection: "column" }}>
            {faqs?.map((instruction, index) => (
              <Accordion
                key={index}
                expanded={expanded === `panel${index}`}
                onChange={handleChange(`panel${index}`)}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls={`panel${index}-content`}
                  id={`panel${index}-header`}
                  style={{ backgroundColor: "#dcdaff" }}
                >
                  <Typography variant="h6" style={{ color: "#4d16a4" }}>
                    {instruction.title}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails style={{ backgroundColor: "#dcdaff" }}>
                  <ol>
                    {instruction.steps.map((step, idx) => (
                      <li key={idx}>
                        <Typography style={{ color: "#4d16a4" }}>
                          {step}
                        </Typography>
                      </li>
                    ))}
                  </ol>
                </AccordionDetails>
              </Accordion>
            ))}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Faq;

// import React, { useState } from "react";
// import {
//   Accordion,
//   AccordionSummary,
//   AccordionDetails,
//   Typography,
//   Container,
//   Grid,
// } from "@mui/material";
// import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
// import Navbar1 from "../../components/Navbar";
// import Footer from "../../landing_page/Footer";
// import faqs from "./faqData";

// const Faq = () => {
//   const [expanded, setExpanded] = useState(false);

//   const handleChange = (panel) => (event, isExpanded) => {
//     setExpanded(isExpanded ? panel : false);
//   };

//   return (
//     <>
//       <Navbar1 />
//       <Container maxWidth="lg" sx={{ padding: "2rem 0" }}>
//         <Typography variant="h4" align="center" gutterBottom>
//           Frequently Asked Questions
//         </Typography>
//         <Container spacing={1}>
//           {faqs.map((instruction, index) => (
//             <Accordion
//               key={index}
//               expanded={expanded === `panel${index}`}
//               onChange={handleChange(`panel${index}`)}
//             >
//               <AccordionSummary
//                 expandIcon={<ExpandMoreIcon />}
//                 aria-controls={`panel${index}-content`}
//                 id={`panel${index}-header`}
//               >
//                 <Typography variant="h6">{instruction.title}</Typography>
//               </AccordionSummary>
//               <AccordionDetails>
//                 <ol>
//                   {instruction.steps.map((step, idx) => (
//                     <li key={idx}>
//                       <Typography>{step}</Typography>
//                     </li>
//                   ))}
//                 </ol>
//               </AccordionDetails>
//             </Accordion>
//           ))}
//         </Container>
//       </Container>
//       <Footer />
//     </>
//   );
// };

// export default Faq;
