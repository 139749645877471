import React from "react";
import "./footer.css";
import Logo from "../assets/landing-page/partner-Landing-logo.png";
import playStore from "../assets/landing-page/playStore.png";
import appStore from "../assets/landing-page/appStore.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faTwitter,
  faInstagram,
  faLinkedinIn,
} from "@fortawesome/free-brands-svg-icons";

const Footer = () => {
  return (
    <footer className="footer-container">
      <div className="footer-content">
        <div className="footer-section logo-contact">
          <img
            src={Logo}
            alt="Batuk Logo"
            className="footer-logo"
            style={{ width: "13rem" }}
          />
          <div className="footer-address">
            <span className="footer-sapn">Address:</span>
            <p className="footer-p" style={{ marginBottom: "0px" }}>
              Office No 2, 3rd floor, A-1, Sector 9, Noida, Uttar Pradesh-
              201301
            </p>
          </div>
          <div className="footer-add">
            <span className="footer-sapn">Phone: </span>
            <p className="footer-p" style={{ marginBottom: "0px" }}>
              {" "}
              +91844695359
            </p>
          </div>
          <div className="footer-add">
            <span className="footer-sapn">Email: </span>
            <p className="footer-p" style={{ marginBottom: "0px" }}>
              {" "}
              connect@batuk.in
            </p>
          </div>
        </div>
        <div className="footer-section links" style={{ paddingTop: "1.5rem" }}>
          <h4>Batuk</h4>
          <ul>
            <li>
              <a href="/home">Home</a>
            </li>
            <li>
              <a href="/about">About Us</a>
            </li>

            <li>
              <a href="/terms-conditions">Terms & Conditions</a>
            </li>
            <li>
              <a href="/privacy-policy">Privacy Policy</a>
            </li>
          </ul>
        </div>
        <div className="footer-section links" style={{ paddingTop: "1.5rem" }}>
          <h4>Explore</h4>
          <ul>
            <li>
              <a href="/faq">FAQ</a>
            </li>
            <li>
              <a href="/blog">Blogs</a>
            </li>
            <li>
              <a href="/shipping">Shipping & Returns</a>
            </li>
          </ul>
        </div>
        {/* <div className="footer-section links" style={{ paddingTop: "1.5rem" }}>
          <div className="subscribe">
            <input type="email" placeholder="Enter email" />
          </div>
          <button className="sub-button">Subscribe Newslatter</button>
        </div> */}
        <div className="footer-section links" style={{ paddingTop: "1.5rem" }}>
          <h4>Follow us on</h4>
          <div className="social-icons" style={{ marginBottom: "1rem" }}>
            <div className="icons-center">
              <a
                href="https://www.facebook.com"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Facebook"
              >
                <FontAwesomeIcon icon={faFacebookF} className="social-icon" />
              </a>
            </div>
            <div className="icons-center">
              <a
                href="https://www.twitter.com"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Twitter"
              >
                <FontAwesomeIcon icon={faTwitter} className="social-icon" />
              </a>
            </div>

            <div className="icons-center">
              <a
                href="https://www.instagram.com"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Instagram"
              >
                <FontAwesomeIcon icon={faInstagram} className="social-icon" />
              </a>
            </div>

            <div className="icons-center">
              <a
                href="https://www.linkedin.com"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="LinkedIn"
              >
                <FontAwesomeIcon icon={faLinkedinIn} className="social-icon" />
              </a>
            </div>
          </div>
          <p>Install App </p>
          <div className="footer-contact">
            <a
              href="https://play.google.com/store/apps/details?id=com.batuk.application&pcampaignid=web_share"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={playStore} alt="Batuk Logo" className="footer-logo" />
            </a>
            <a href="" target="_blank" rel="noopener noreferrer">
              <img src={appStore} alt="Batuk Logo" className="footer-logo" />
            </a>
          </div>
        </div>
      </div>
      <hr />
      <div className="footer-bottom">
        <p>
          © Copyright 2024 Bharat Batuk Private Limited All rights reserved.
        </p>
      </div>
    </footer>
  );
};

export default Footer;
