import React from "react";
import "./PartnerLanding.css";
import teamWork from "../assets/landing-page/get-started.png";
import handshakeIcon from "../assets/landing-page/Handshake.png"; // Replace with the path to your images
import investmentIcon from "../assets/landing-page/rupee.png";
import productsIcon from "../assets/landing-page/briefcase.png";
import { Link } from "react-router-dom";

const PartnerLanding = () => {
  return (
    <div className="landing-container">
      <div className="hero-section">
        <div className="hero-text-container">
          <h3 className="hero-title">Boost your earnings with Batuk!</h3>
          <p className="hero-description">
            Experience unparalleled support, smart tools, and step-by-step
            assistance. Join Batuk and transform your wealth journey today!
          </p>
          <Link to="/login" className="get-started-btn">
            Get Started
          </Link>
        </div>
        <div className="hero-image-container">
          <img className="hero-image" src={teamWork} alt="Teamwork" />
        </div>
      </div>
      <div className="stats-section">
        <div className="stat-item">
          <img className="stat-icon" src={investmentIcon} alt="Investment" />
          <h3 className="stat-title">50 Crore+ Investments</h3>
          <p className="stat-description">Managed by Batuk Partners</p>
        </div>
        <div className="stat-item">
          <img className="stat-icon" src={handshakeIcon} alt="Partners" />
          <h3 className="stat-title">500+ Partners</h3>
          <p className="stat-description">From 150+ towns & cities in India</p>
        </div>
        <div className="stat-item">
          <img className="stat-icon" src={productsIcon} alt="Products" />
          <h3 className="stat-title">5+ Products</h3>
          <p className="stat-description">Batuk Offerings</p>
        </div>
      </div>
    </div>
  );
};

export default PartnerLanding;

// import React from "react";
// import "./PartnerLanding.css";
// import teamWork from "../assets/landing-page/get-started.png";
// import handshakeIcon from "../assets/landing-page/Handshake.png"; // Replace with the path to your images
// import investmentIcon from "../assets/landing-page/rupee.png";
// import productsIcon from "../assets/landing-page/briefcase.png";

// const PartnerLanding = () => {
//   return (
//     <div className="landing-container">
//       <div className="hero-section">
//         <div className="hero-text">
//           <h1>Boost your earnings with Batuk!</h1>
//           <p>
//             Experience unparalleled support, smart tools, and step-by-step
//             assistance. Join Batuk and transform your wealth journey today!
//           </p>
//           <button className="get-started-btn">Get Started</button>
//         </div>
//         <div className="hero-image">
//           <img src={teamWork} alt="Teamwork" />
//         </div>
//       </div>
//       <div className="stats-section">
//         <div className="stat-item">
//           <img src={investmentIcon} alt="Investment" />
//           <h3>50 Crore+ Investments</h3>
//           <p>Managed by Batuk Partners</p>
//         </div>
//         <div className="stat-item">
//           <img src={handshakeIcon} alt="Partners" />
//           <h3>500+ Partners</h3>
//           <p>From 150+ towns & cities in India</p>
//         </div>
//         <div className="stat-item">
//           <img src={productsIcon} alt="Products" />
//           <h3>5+ Products</h3>
//           <p>Batuk Offerings</p>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default PartnerLanding;
